<!-- 调整预测弹框 -->
<template>
  <div>
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="serarchForeToProps"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="serarchForeCancel"
      append-to-body
    >
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="">
          <el-input
            v-model="formInline.user"
            :placeholder="$t('label.searchs')"
            @change="Userfilter()"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onsumit">{{
            $t("button_partner_search")
          }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="optionListtProps"
        border
        style="width: 100%"
        height="400"
        v-loading="loading"
      >
        <el-table-column
          v-for="(item, index) in tableDetailHeader"
          :key="index"
          :label="item.nameLabel"
        >
          <template slot-scope="scope">
            <div v-if="item.id == '1'">
              <p @click="sx(scope.row)">{{ scope.row.name }}</p>
            </div>
            <div v-if="item.id == '2'">
              <p>{{ scope.row.roleccname }}</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getLookupResults } from "../../api.js";
export default {
  props: {
    serarchForeTo: {
      // 弹框显示隐藏
      type: Boolean,
      default: false,
    },
    optionListt: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      optionListtProps:this.optionListt,
      serarchForeToProps:this.serarchForeTo,
      lktp: "005", //对象的前缀
      lknm: "appraduserlookup___0", //查找字段的名称
      lkdp: "forecastuser",
      lksrch: "",
      serarchS: [
        {
          serarchId: "",
          serarchName: "",
        },
      ],
      loading: false,
      formInline: {
        user: "",
        region: "",
      },
      tableData: null,
      tableDetailHeader: [
        {
          id: "1",
          nameLabel: "用户",
        },
        {
          id: "2",
          nameLabel: "角色",
        },
      ],
    };
  },
  watch: {
    onsumit() {
      this.Userfilter();
    },
  },
  mounted() {},
  methods: {
    Userfilter() {
      this.loading = true;
      this.lksrch = this.formInline.user;
      this.getLookupResults();
    },
    onsumit() {
      this.loading = true;
      this.lksrch = this.formInline.user;
      this.getLookupResults();
    },
    async getLookupResults() {
      let res = await getLookupResults({
        lktp: this.lktp,
        lknm: this.lknm,
        lksrch: this.lksrch,
        lkdp: this.lkdp,
      });
      this.optionListtProps = res.data.objlist;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    //关闭弹窗
    serarchForeCancel() {
      this.$emit("serarchForeCancel");
    },
    save() {
      this.serarchForeCancel();
    },
    //筛选
    sx(row) {
      // 
      this.serarchS.serarchId = row.id;
      this.serarchS.serarchName = row.name;
      this.$emit("sx", this.serarchS);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  border-top: none;
}
.dialog-footer {
  p {
    font-size: 12px;
    color: #006dcc;
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
}
::v-deep .el-table {
  thead {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #3e3e3c;
    letter-spacing: 0;
  }
  tr,
  td {
    background: #fff;
    border-right: 0;
  }
  td:hover {
    background: #fff;
  }
  th {
    background: rgb(250, 250, 249);
  }
}
::v-deep .el-input__inner {
  width: 315px;
  height: 30px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}
::v-deep .el-dialog__body {
  padding: 16px 8px;
}
::v-deep .el-textarea__inner {
  width: 315px;
  height: 87px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}
::v-deep .el-form-item:nth-child(1) {
  margin-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 14px;
  color: #080707;
}
::v-deep label {
  font-size: 12px;
  color: #888888;
}
::v-deep .el-form-item:nth-child(2) {
  .el-form-item__label {
    font-size: 12px;
    color: #888888;
  }
}
</style>