<template>
  <div class="form_top">
    <el-table
      ref="forecastTop"
      :data="tableArr"
      class="customer-table"
      style="width: 100%"
      row-key="id"
      lazy
      border
      v-loading="loading"
      show-summary
      :load="load"
      :row-style="tableRowClassName"
      :height="tableHeight"
      :span-method="getSpanmethod"
      :summary-method="getSummaries"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-cell-style="{
        height: '35px',
        padding: '0 0 2px 0',
      }"
      @row-click="rowClick"
      @cell-click="cellClick"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
    >
      <el-table-column prop="date" :label="dateLabel" width="287">
        <template slot-scope="scope">
          <div class="boxcls" style="display:flex;align-items: center;">
            <span v-show="scope.row.isImages">
              <img
                :src="getPersonChargeImg(scope.row)"
                alt=""
                class="userImg"
              />
            </span>
            <span v-if="setfore" class="nname">
              {{ scope.row.date }}
            </span>
            <span v-if="!setfore" class="nname">
              {{ scope.row.date }}
            </span>
            <span
              v-show="scope.row.zhuantu"
              @click="goDeta(scope.row)"
              class="jiantou"
            >
            <svg aria-hidden="true"><use xlink:href="#icon-forecastjiantou"></use></svg>
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- 定额 -->
      <el-table-column
        prop="quotas"
        :label="$t('label.userquotas')"
        align="center"
        v-if="quota"
      >
        <template slot-scope="scope">
          <div class="boxcls" @click.stop>
            <!-- {{scope.row.director }} {{userid}} -->
            <p v-show="income && scope.row.director != userid">{{ scope.row.quotas }}</p>
            <p v-show="income && scope.row.director == userid">-</p>
            <p v-show="amount && scope.row.director != userid">{{ scope.row.quotaqnt }}</p>
            <p v-show="amount && scope.row.director == userid">-</p>
          </div>
        </template>
      </el-table-column>
      <!-- 定额差值 -->
      <!-- 定额差值 = 定额 - 已签单 (定额差为0或负数时，显示为0) -->
      <el-table-column
        prop="quotas"
        :label="$t('c1068')"
        align="center"
        v-if="forcastType == '0'"
      >
        <template slot-scope="scope">
          <div class="boxcls" @click.stop>
            <!-- 用scope.row.director == userid 判断是不是当前用户 -->
            <p v-if="income && scope.row.director != userid">
              {{ getNumber(scope.row.quotas, scope.row.closeds)}}
            </p>
            <p v-if="income && scope.row.director == userid">
              -
            </p>
            <p v-if="amount && scope.row.director != userid">
              {{ getNumValue(scope.row.quotaqnt, scope.row.quotas)}}
            </p>
            <p v-if="amount && scope.row.director == userid">
             -
            </p>
          </div>
        </template>
      </el-table-column>
      <!-- 已签单 -->
      <el-table-column
        prop="closeds"
        :label="$t('label.forecast.customforecast.foretype.close')"
        align="center"
      >
        <template slot-scope="scope">
          <div class="boxcls">
            <p v-if="income">
              {{  scope.row.closeds,}}
            </p>
            <p v-if="income">
              <span v-if="percents">
                {{ getPercent(scope.row.closeds, scope.row.quotas) }}
              </span>
            </p>
            <p v-if="amount">
              {{ scope.row.closedsqnt }}
            </p>
            <p v-if="amount">
              <span v-if="percents">
                {{ getPercent(scope.row.closedsqnt, scope.row.quotaqnt) }}
              </span>
            </p>
          </div>
        </template>
      </el-table-column>
      <!-- 承诺额 -->
      <el-table-column
        prop="commits"
        :label="$t('label.forecast.customforecast.foretype.commit')"
        align="center"
      >
        <template slot-scope="scope">
          <div class="editclass">
            <p v-if="income">
              {{ scope.row.commits }}
            </p>
            <p v-if="income">
              <span v-if="percents">
                {{ getPercent(scope.row.commits, scope.row.quotas) }}
              </span>
            </p>
            <p v-if="amount">
              {{ scope.row.commitsqnt }}
            </p>
            <p v-if="amount">
              <span v-if="percents">
                {{ getPercent(scope.row.commitsqnt, scope.row.quotaqnt) }}
              </span>
            </p>
              <svg class="editimg" v-show="false" aria-hidden="true" 
              @click.stop="editforeCell(scope.row, scope.column)">
                <use xlink:href="#icon-pen"></use>
              </svg>
            <div v-show="income">
              <el-popover
                v-show="scope.row.isrepcommits"
                @click.stop.native
                placement="bottom"
                width="308"
                trigger="hover"
                popper-class="popover"
              >
                <p>
                  {{ foreuser }}:<span>{{ scope.row.commits }}</span>
                </p>
                <p>
                  {{ $t("label_not_adjust") }}:<span>{{
                    scope.row.commitamt
                  }}</span>
                </p>
                <p>
                  {{ $t("label_adjust_remark") }}:<span>{{
                    scope.row.commitamtmark
                  }}</span>
                </p>
                <div class="ycbutton" slot="reference"></div>
              </el-popover>
            </div>
            <div v-show="amount">
              <el-popover
                v-show="scope.row.isrepcommitsqnt"
                @click.stop.native
                placement="bottom"
                width="308"
                trigger="hover"
                popper-class="popover"
              >
                <p>
                  {{ foreuser }}:<span>{{ scope.row.commitsqnt }}</span>
                </p>
                <p>
                  {{ $t("label_not_adjust") }}:<span>{{
                    scope.row.commitqnt
                  }}</span>
                </p>
                <p>
                  {{ $t("label_adjust_remark") }}:<span>{{
                    scope.row.commitqntmark
                  }}</span>
                </p>
                <div class="ycbutton" slot="reference"></div>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 超长发挥 -->
      <el-table-column
        prop="bests"
        :label="$t('label.forecast.ccfh')"
        align="center"
      >
        <template slot-scope="scope">
          <div
            class="editclass"
            @dblclick.stop="editforeCell(scope.row, scope.column)"
          >
            <p v-if="income">
              {{ scope.row.bests }}
            </p>
            <p v-if="income">
              <span v-if="percents">
                {{ getPercent(scope.row.bests, scope.row.quotas) }}
              </span>
            </p>
            <p v-if="amount">
              {{ scope.row.bestsqnt }}
            </p>
            <p v-if="amount">
              <span v-if="percents">
                {{ getPercent(scope.row.bestsqnt, scope.row.quotaqnt) }}
              </span>
            </p>
             <svg class="editimg" v-show="false" aria-hidden="true" 
              @click.stop="editforeCell(scope.row, scope.column)">
                <use xlink:href="#icon-pen"></use>
              </svg>
            <div v-show="income">
              <el-popover
                v-show="scope.row.isrepbests"
                @click.stop.native
                placement="bottom"
                width="308"
                trigger="hover"
                popper-class="popover"
              >
                <p>
                  {{ foreuser }}:<span>{{ scope.row.bests }}</span>
                </p>
                <p>
                  {{ $t("label_not_adjust") }}:<span>{{
                    scope.row.bestcaseamt
                  }}</span>
                </p>
                <p>
                  {{ $t("label_adjust_remark") }}:<span>{{
                    scope.row.bestcasetamtmark
                  }}</span>
                </p>
                <div class="ycbutton" slot="reference"></div>
              </el-popover>
            </div>
            <div v-show="amount">
              <el-popover
                v-show="scope.row.isrepbestsqnt"
                @click.stop.native
                placement="bottom"
                width="308"
                trigger="hover"
                popper-class="popover"
              >
                <p>
                  {{ foreuser }}:<span>{{ scope.row.bestsqnt }}</span>
                </p>
                <p>
                  {{ $t("label_not_adjust") }}:<span>{{
                    scope.row.bestcaseqnt
                  }}</span>
                </p>
                <p>
                  {{ $t("label_adjust_remark") }}:<span>{{
                    scope.row.bestcasetqntmark
                  }}</span>
                </p>
                <div class="ycbutton" slot="reference"></div>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 处理中 -->
      <el-table-column
        prop="pipelines"
        :label="$t('label.weixin.inhead')"
        align="center"
        popper-class="popover"
      >
        <template slot-scope="scope">
          <div class="boxcls">
            <p v-if="income">
              {{ scope.row.pipelines }}
            </p>
            <p v-if="income">
              <span v-if="percents">
                {{ getPercent(scope.row.pipelines, scope.row.quotas) }}
              </span>
            </p>
            <p v-if="amount">
              {{ scope.row.pipelinesqnt }}
            </p>
            <p v-if="amount">
              <span v-if="percents">
                {{ getPercent(scope.row.pipelinesqnt, scope.row.quotaqnt) }}
              </span>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <editFore
      :nowForem="nowForem"
      :nowForeDescription="nowForeDescription"
      :editForeTo="editForeTo"
      :forenum="forenum"
      @clearance="clearance"
      @editForeCancel="editForeCancel"
      @editForeConfirm="editForeConfirm"
    />
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import editFore from "./forecastDialog/editFore"; //调整预测
import {
  getForecastEditTotal,
  getForecastSaveTotal,
  getForecastPermission,
} from "../api.js";
export default {
  components: {
    editFore,
  },
  props: {
    topTablesum: {
      type: Array,
      default: () => [],
    },
    // 总数
    sumNumber: {
      typeof: String,
      default: "",
    },
    tableArr: {
      type: Array,
      default: () => [],
    },
    cpxllist: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    quota: {
      type: Boolean,
      default: true,
    },
    // 显示定额完成百分比
    percents: {
      type: Boolean,
      default: true,
    },
    // 代表显示的业务机会收入
    income: {
      type: Boolean,
      default: false,
    },
    // 代表显示的业务机会数量
    amount: {
      type: Boolean,
      default: false,
    },
    setfore: {
      type: Boolean,
    },
    userid: {
      typeof: String,
      default: "",
    },
    forenum: {
      typeof: String,
      default: "",
    },
    foreperiod: {
      typeof: String,
      default: "",
    },
    foredis: {
      typeof: String,
      default: "",
    },
    totalType: {
      typeof: String,
      default: "",
    },
    foretype: {
      typeof: String,
      default: "",
    },
    director: {
      typeof: String,
      default: "",
    },
    foreuser: {
      typeof: String,
      default: "",
    },
    tableArrChildren: {
      typeof: Array,
      default: null,
    },
    appraduserlookup___0_lkid: {
      typeof: String,
      default: "",
    },
    // 是否是经理
    ismanager:{
      type: String,
    },
    // 预测类型 (0代表整体预测)
    forcastType: {
      type: String,
      default: "0"
    },
  },
  data() {
    return {
      nowForem: "", //编辑前的预测
      nowForeDescription: "", //编辑前的备注
      editForeTo: false, //调整预测弹框
      tableHeight: null,
      percent: "-",
      iszearo: true,
      foreyear: "",
      foremon: "",
      nowForeme: "", //编辑后
      commitamt: "", //承诺额收入预测值
      commitamtmark: "",
      commitqntmark: "", //承诺额收入预测备注
      commitqnt: "", //承诺额数量预测值
      bestcaseamt: "", //超常发挥收入预测值
      bestcaseamtmark: "", //超常发挥收入预测备注
      bestcaseqnt: "", //承诺额超常发挥数量预测值
      bestcaseqntmark: "", //超常发挥数量预测备注
      addId: "", //编辑时要传id
      cpxl: "",
      formm: {
        totalTypeee: "",
        currencyy: "",
        foremonn: "",
        forenum: "",
        directorr: "",
        cpxl: "",
      },
      goUser: {
        nameId: "",
        Username: "",
      },
      isedit: false,
      // 头像
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      sjdate: "",
      perioadnumber: "",
      commitamtoption: "4",
      commitqntoption: "4",
      bestcaseamtoption: "4",
      bestcaseqntoption: "4",
      newClick: null,
      new: null,
      old: null,
      newColumn: null,
      rowC: null,
      dateLabel: "",
    };
  },
  watch: {
    tableArr:{
      handler(){
      },
      deep:true,
      immediate:true
    },
    setfore() {
      if (this.setfore === true) {
        // 月份
        this.dateLabel = this.$i18n.t("label.mobile.phone.month");
      } else {
        // 季度
        this.dateLabel = this.$i18n.t("label.account.quarter");
      }
    },
    percent() {
    },
    newClick(val, oldVal) {
      this.$nextTick(() => {
        this.new = val;
        this.old = oldVal;
        if(this.newColumn.property !== "date" &&
          this.newColumn.property !== "quotas" &&
          this.rowC.isFilter !== false){
            if(this.new&&this.new.style){
              this.new.style.border = "1px solid #156bb4";
            }
          if(this.old&&this.old.style){
            this.old.style.border = "none";
          this.old.style.borderBottom = "1px solid #DEDCDA";
          }
          
        }
      });
    },
  },
  created() {
    // this.percent="100%"
  },
  mounted() {
    this.getForecastPermission();
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    //监测底部表格高度更改上部分表格高度
    this.$Bus.$off('bottom-table-height')
    this.$Bus.$on('bottom-table-height',this.ebFn)
  },
  beforeDestroy(){
    this.$Bus.$off('bottom-table-height',this.ebFn) 
  },
  methods: {
    ebFn(val){
      let heightBottom=document.getElementsByClassName("forecast_content")[0].clientHeight
      if(!isNaN(val)&&!isNaN(heightBottom)){
        this.tableHeight =heightBottom -133 - val +"px";
      }
      
    
    },
    //清除调整
    clearance(ruleForm) {
      this.commitamtoption = "1";
      this.commitqntoption = "1";
      this.bestcasetamtoption = "1";
      this.bestcasetqntoption = "1";
      this.editForeConfirm(ruleForm);
    },
    //设置头像
    getPersonChargeImg(row) {
      let ids = "";
      if (row.director) {
        ids = row.director;
        return `${this.imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${ids}&binding=${this.tokenCookie}`;
      } else {
        ids = row.forecastor;
        return `${this.imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${ids}&binding=${this.tokenCookie}`;
      }
    },
    //查编辑权限
    getForecastPermission() {
      getForecastPermission({ name: "OverrideForecast" }).then((res) => {
        if (res.data == 'true') {
          this.isedit = true;
        } else {
          this.isedit = false;
        }
      });
    },
    goDeta(row) {
      if (row.username) {
        this.goUser.nameId = row.director;
        this.goUser.Username = row.username;
        this.$emit("goDeta", this.goUser);
      }
    },
    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 计算数量差值
    getNumValue(num, numT) {
      if (this.income === false && this.amount === true) {
        num = num.split(".")[0];
        numT = numT.split(".")[0];
        num = num.replace(/,/gi, "");
        numT = numT.replace(/,/gi, "");
        if(isNaN(num) || num == "undefined") {
          num = "0.00"
        }
        if(isNaN(numT) || numT == "undefined") {
          numT = "0.00"
        }
        let numC = parseInt(num - numT);
        return numC <= 0 ? "0.00" : this.changeMicrometerLevel(String(numC.toFixed(2)));
        }
    },
    // 计算金额差值
    getNumber(money,moneyT) {
      if (this.income === true && this.amount === false) {
        // money 定额 moneyT 已签单
        let moneyCurry = money;
        money = money.split(" ")[1];
        money = money.replace(/,/gi, "");
        moneyT = moneyT.split(" ")[1];
        moneyT = moneyT.replace(/,/gi, "");
        if(money == "-" || moneyT == "-") {
          return "-"
        }
        if(isNaN(money) || money == "undefined") {
          money = this.$store.state.userInfoObj.currencyCode + "0.00"
        }
        if(isNaN(moneyT) || moneyT == "undefined") {
          moneyT = this.$store.state.userInfoObj.currencyCode + "0.00"
        }
        let num = money - moneyT;
        return num <= 0 ? moneyCurry.split(" ")[0] + " 0.00" : moneyCurry.split(" ")[0] + " " + this.changeMicrometerLevel(String(num.toFixed(2)));
        }
    },
    //计算百分比
    getPercent(num, total) {
      if (this.income === true && this.amount === false) {
        num = num.split(" ")[1];
        total = total.split(" ")[1];
        num = num.replace(/,/gi, "");
        num = parseInt(num);
        total = total.replace(/,/gi, "");
        total = parseInt(total);
        if(num == "-" || total == "-") {
          return "-"
        }
        if (isNaN(num) || isNaN(total)) {
          return "-";
        }
      } else {
        num = num.split(".")[0];
        total = total.split(".")[0];
        num = num.toString();
        total = total.toString();
        num = num.replace(/,/gi, "");
        total = total.replace(/,/gi, "");
        num = parseInt(num);
        total = parseInt(total);
        if (isNaN(num) || isNaN(total)) {
          return "-";
        }
      }
      return total <= 0 ? "-" : Math.round((num / total) * 10000) / 100.0 + "%";
    },
    load(tree, treeNode, resolve) {
      setTimeout(() => {
        resolve(this.tableArrChildren);
      }, 1000);
    },
    cellClick(row, column, cell) {
      if (cell !== null) {
        this.newClick = cell;
        this.newColumn = column;
        this.rowC = row;
      }
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell) {
      if (
        (column.property === "commits" &&
          this.isedit === true &&
          row.isEddit === true) ||
        (column.property === "bests" &&
          this.isedit === true &&
          row.isEddit === true)
      ) {
        cell.getElementsByClassName("editimg")[0]
          ? (cell.getElementsByClassName("editimg")[0].style.display = "")
          : "";
      }
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell) {
      cell.getElementsByClassName("editimg")[0]
        ? (cell.getElementsByClassName("editimg")[0].style.display = "none")
        : "";
    },
    //  showRow(row) {
    //     const show = (row.row.parent ? (row.row.parent._expanded && row.row.parent._show) : true);
    //     row.row._show = show;
    //     return show ? 'animation:treeTableShow 1s;-webkit-animation:treeTableShow 1s;' : 'display:none;'
    //   },
    //   // 切换下级是否展开
    //   toggleExpanded(trIndex) {
    //     const record = this.formatData[trIndex]
    //     record._expanded = !record._expanded
    //   },
    //获取当前点击行下标
    tableRowClassName() {
      //把每一行的索引放进row
      // let sum = parseInt(
      //   row.quotas.split(" ")[1] +
      //     row.closeds.split(" ")[1] +
      //     row.bests.split(" ")[1] +
      //     row.commits.split(" ")[1] +
      //     row.pipelines.split(" ")[1]
      // );
    },
    //点击数据触发
    rowClick(row, column) {
      if (
        column.property !== "date" &&
        column.property !== "quotas" &&
        row.isFilter !== false
      ) {
        let directorr = ""
        if(row.director == this.userid && this.forcastType == "0") {
          directorr = "null";
        } else {
          directorr = row.director;
        }
        //判断点击那个表头数据及是否是收入
        if (column.property === "closeds" && this.income === true) {
          // 判断当前数据 是 当前用户数据 && 整体预测
          
          this.formm.currencyy = row.currency;
          this.formm.totalTypeee = "closed";
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "0";
          this.formm.directorr = directorr;
          this.sjdate = row.date;
          this.formm.cpxl = row.series;
        } else if (column.property === "closeds" && this.income === false) {
          this.formm.totalTypeee = "closed";
          this.formm.currencyy = row.currencystp;
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "1";
          this.formm.directorr = directorr;
          this.sjdate = row.date;
          this.formm.cpxl = row.series;
        }
        if (column.property === "commits" && this.income === true) {
          this.formm.currencyy = row.currency;
          this.formm.totalTypeee = "commit";
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "0";
          this.formm.directorr = directorr;
          this.formm.cpxl = row.series;
          this.sjdate = row.date;
        } else if (column.property === "commits" && this.income === false) {
          this.formm.totalTypeee = "commit";
          this.formm.currencyy = row.currencystp;
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "1";
          this.formm.directorr = directorr;
          this.sjdate = row.date;
          this.formm.cpxl = row.series;
        }
        if (column.property === "bests" && this.income === true) {
          this.formm.currencyy = row.currency;
          this.formm.totalTypeee = "best";
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "0";
          this.formm.cpxl = row.series;
          this.formm.directorr = directorr;
          this.sjdate = row.date;
        } else if (column.property === "bests" && this.income === false) {
          this.formm.totalTypeee = "best";
          this.formm.currencyy = row.currencystp;
          this.formm.foremonn = row.forecastperiod;
          this.formm.forenum = "1";
          this.formm.directorr = directorr;
          this.formm.cpxl = row.series;
          this.sjdate = row.date;
        }
        if (column.property === "pipelines" && this.income === true) {
          this.formm.currencyy = row.currency;
          this.formm.totalTypeee = "pipeline";
          this.formm.foremonn = row.forecastperiod;
          this.formm.directorr = directorr;
          this.formm.cpxl = row.series;
          this.formm.forenum = "0";
          this.sjdate = row.date;
        } else if (column.property === "pipelines" && this.income === false) {
          this.formm.currencyy = row.currencystp;
          this.formm.totalTypeee = "pipeline";
          this.formm.foremonn = row.forecastperiod;
          this.formm.directorr = directorr;
          this.formm.cpxl = row.series;
          this.formm.forenum = "1";
          this.sjdate = row.date;
        }
        this.$emit("rowClick", this.formm);
      }
    },
    //调整
    editforeCell(row, column) {
      this.editForeTo = true;
      this.foreyear = row.forecastyear;
      this.foremon = row.forecastperiod;
      if (column.property === "commits") {
        this.formm.totalTypeee = "commit";
        this.formm.forenum = "0";
        // this.nowForem = row.commitsqnt;
        this.formm.currencyy = row.currency;
        this.cpxl = row.series;
        //有下级的话取下级id否则取用户id
        if (row.director) {
          this.formm.directorr = row.director;
        } else {
          this.formm.directorr = this.userid;
        }
      }
      if (column.property === "bests") {
        this.formm.totalTypeee = "best";
        this.formm.forenum = "0";
        // this.nowForem = row.commits;
        this.formm.currencyy = row.currency;
        this.cpxl = row.series;
        if (row.director) {
          this.formm.directorr = row.director;
        } else {
          this.formm.directorr = this.userid;
        }
      }
      if (column.property === "commits" && this.income === false) {
        this.formm.totalTypeee = "commit";
        this.formm.forenum = "1";
        this.cpxl = row.series;
        if (row.director) {
          this.formm.directorr = row.director;
        } else {
          this.formm.directorr = this.userid;
        }
      }
      if (column.property === "bests" && this.income === false) {
        this.formm.forenum = "1";
        this.formm.totalTypeee = "best";
        this.cpxl = row.series;
        if (row.director) {
          this.formm.directorr = row.director;
        } else {
          this.formm.directorr = this.userid;
        }
      }
      //编辑业务机会预测
      let params = {
        userid: this.userid,
        forenum: this.forenum,
        foreyear: this.foreyear,
        foremon: this.foremon,
        foretype: this.foretype,
        foreperiod: this.foreperiod,
        foredis: this.foredis,
        totalType: this.formm.totalTypeee,
        director: this.formm.directorr,
        cpxl: (this.cpxl===this.$i18n.t("label.tabpage.nothingz")||!this.cpxl)?'':this.cpxl,
      };
      //保存前调用
      getForecastEditTotal(params).then((res) => {
        // this.forenum:预测类型：'0':收入，'1':数量
        if(this.forenum==='1'){
          this.nowForem = res.data.forecast.xszqnt;
        }else{
          this.nowForem = res.data.forecast.xsz;
        }
        
        this.addId = res.data.forecast.id;
        //给编辑的数据赋值，如不赋值第一次编辑以后其他的会清空
        this.commitamtoption = res.data.forecast.commitamtoption;
        this.commitqntoption = res.data.forecast.commitqntoption;
        this.commitamt = res.data.forecast.commitamt;
        this.commitamtmark = res.data.forecast.commitamtmark;
        this.commitqnt = res.data.forecast.commitqnt;
        this.commitqntmark = res.data.forecast.commitqntmark;
        this.bestcasetamoption = res.data.forecast.bestcasetamoption;
        this.bestcaseqntoption = res.data.forecast.bestcaseqntoption;
        this.bestcaseamt = res.data.forecast.bestcaseamt;
        this.bestcaseamtmark = res.data.forecast.bestcaseamtmark;
        this.bestcaseqnt = res.data.forecast.bestcaseqnt;
        this.bestcaseqntmark = res.data.forecast.bestcaseqntmark;
        if (this.addId == "" || this.addId == null || this.addId == undefined) {
          this.perioadnumber = "";
        } else {
          if (this.foremon < 10) {
            this.perioadnumber = this.foreyear + "0" + this.foremon;
          } else {
            this.perioadnumber = this.foreyear + this.foremon;
          }
        }
      });
    },
    //保存预测
    editForeConfirm(ruleForm) {
      if (this.forenum == "0" && this.formm.totalTypeee == "commit") {
        this.commitamt = ruleForm.forenum;
        this.commitamtmark = ruleForm.desc;
      }
      if (this.forenum == "1" && this.formm.totalTypeee == "commit") {
        this.commitqnt = ruleForm.forenum;
        this.commitqntmark = ruleForm.desc;
      }
      if (this.forenum == "0" && this.formm.totalTypeee == "best") {
        this.bestcaseamt = ruleForm.forenum;
        this.bestcaseamtmark = ruleForm.desc;
      }
      if (this.forenum == "1" && this.formm.totalTypeee == "best") {
        this.bestcaseqnt = ruleForm.forenum;
        this.bestcaseqntmark = ruleForm.desc;
      }
      let ismanager=this.ismanager==='1'?'1':'4'
      let params = {
        // 如果有下级，即this.ismanager==='1',值是'1',否则是'4'
        // commitamtoption	否	string	承诺额收入预测选项 1，2，3，4
        // commitqntoption	否	string	承诺额数量预测选项 1，2，3，4
        // bestcasetamtoption	否	string	超常发挥收入预测选项 1，2，3，4
        // bestcasetqntoption	否	string	超常发挥数量预测选项 1，2，3，4
        forecast: {
          forecattype: this.foreperiod,
          forecastyear: this.foreyear,
          forecastperiod: this.foremon,
          id: this.addId,
          forecastor: this.userid,
          director: this.formm.directorr,
          currency: this.formm.currencyy,
          commitamtoption: ismanager,
          commitqntoption: ismanager,
          commitamt: this.commitamt,
          commitamtmark: this.commitamtmark,
          commitqnt: this.commitqnt,
          commitqntmark: this.commitqntmark,
          bestcasetamoption: ismanager,
          bestcaseqntoption: ismanager,
          bestcaseamt: this.bestcaseamt,
          bestcaseamtmark: this.bestcaseamtmark,
          bestcaseqnt: this.bestcaseqnt,
          bestcaseqntmark: this.bestcaseqntmark,
          perioadnumber: this.perioadnumber,
          productfamily: (this.cpxl===this.$i18n.t("label.tabpage.nothingz")||!this.cpxl)?'':this.cpxl,
        },
      };
      getForecastSaveTotal(params).then(() => {
        // this.editforeCell(row,column);
        let paramss = {
          userid: this.userid,
          forenum: this.forenum,
          foreyear: this.foreyear,
          foremon: this.foremon,
          foretype: this.foretype,
          foreperiod: this.foreperiod,
          foredis: this.foredis,
          totalType: this.formm.totalTypeee,
          cpxl: (this.cpxl===this.$i18n.t("label.tabpage.nothingz")||!this.cpxl),
          director: this.formm.directorr,
        };
        getForecastEditTotal(paramss).then((res) => {
          this.nowForem = res.data.bestcaseamt;
          this.addId = res.data.forecast.id;
        });
        this.editForeCancel();
        this.$parent.refresh();
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_notice_modify_success"),
          type: "success",
        });
      });
    },
    // 关闭调整预测弹框
    editForeCancel() {
      this.editForeTo = false;
    },
    // zhong() {},
    getSpanmethod() {},
    //总数
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // 总数
        if (index === 0) {
          sums[index] = this.$i18n.t("label.sum") + `:${this.sumNumber}`;
          return;
        } 
        // 定额
        else if (index === 1) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.topTablesum.desum;
          } else if (this.income === false && this.amount === true) {
            sums[index] = this.topTablesum.deqntsum;
          }
        } 
        // 定额差值
        else if (index === 2) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.getNumber(this.topTablesum.desum, this.topTablesum.yqdsum);
          } else {
            sums[index] = this.getNumValue(this.topTablesum.deqntsum, this.topTablesum.yqdqntsum);
          }
        }
        // 已签单
        else if (index === 3) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.topTablesum.yqdsum;
          } else if (this.income === false && this.amount === true) {
            sums[index] = this.topTablesum.yqdqntsum;
          }
        } 
        // 承诺额
        else if (index === 4) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.topTablesum.cnesum;
          } else if (this.income === false && this.amount === true) {
            sums[index] = this.topTablesum.cneqntsum;
          }
        } 
        // 超长发挥
        else if (index === 5) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.topTablesum.ccfhsum;
          } else if (this.income === false && this.amount === true) {
            sums[index] = this.topTablesum.ccfhqntsum;
          }
        } 
        // 处理中
        else if (index === 6) {
          if (this.income === true && this.amount === false) {
            sums[index] = this.topTablesum.clzsum;
          } else if (this.income === false && this.amount === true) {
            sums[index] = this.topTablesum.clzqntsum;
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
.quota {
  display: none;
}
.boxcls {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #080707;
  letter-spacing: 0;
}
::v-deep .el-table {
  td {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
  }
}
::v-deep .el-table .el-table__cell {
  padding: 7px 0;
}
.tiaozuan {
  width: 10px;
  height: 10px;
}
.userImg {
  width: 35px;
  height: 35px;
  margin-top: 0px;
  margin-right: 10px;
  border-radius: 100%;
}
.editclass {
  justify-content: center;
  align-items: center;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #080707;
  flex-wrap: wrap;
  letter-spacing: 0;
  p {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .editimg {
    width: 12px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    height: 12px;
    top: 40%;
  }
}
p {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
img {
  margin-top: 10px;
}
::v-deep .el-table {
  border: 1px solid#DEDCDA;
  thead {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #3e3e3c;
    letter-spacing: 0;
    border-right: none;
    border-left: none;
    border-top: 1px solid#DEDCDA;
    border-bottom: 1px solid#DEDCDA;
  }
  tr,
  td {
    background: #fff;
    border-right: 0;
    border-bottom: 1px solid#DEDCDA;
  }
  td:hover {
    // background: #fff;
  }
   td:nth-child(1){
    .cell{
       display: flex;
       justify-content: flex-start !important;
       line-height: 20px;
    }
  }
  th {
    border-right: none;
    border-left: none;
    background: #fafaf9;
    .cell{
      line-height: 20px;
    }
  }
  th:nth-child(1){
    .cell{
       display: flex;
       justify-content: flex-start !important;
       line-height: 20px;
    }
  }
}
::v-deep .el-table {
  display: flex;
  flex-direction: column;
  .el-table_1_column_1 {
    // display: flex;
    // justify-content: flex-start !important;
    // border-bottom: 1px solid#DEDCDA;
    // line-height: 20px;
  }
  .cell {
    display: flex;
    // cursor: pointer;
    text-align: center;
    padding-right: 50px;
    line-height: 12px;
    justify-content: flex-end;
    .el-table__placeholder{
      flex-shrink: 0;
    }
    
  }
}

//  ::v-deep .el-table_1_column_1 .cell {
//       display: flex;

// }
::v-deep .el-table_1_column_1 .cell {
  display: flex;
  justify-content: flex-start !important;
  line-height: 20px;
}
/* order默认值为0，将表体order置为1即可移到最后合计行就上移到表体上方 */
::v-deep .el-table__body-wrapper {
  order: 1;
}
::v-deep .el-table__body,
.el-table__footer,
.el-table__header {
  background: #fafaf9;
  border-top: 1px solid#DEDCDA;
}
.ycbutton {
  position: absolute;
  right: 0px;
  top: 0px;
  border-top: 20px solid transparent;
  width: 0px;
  height: 0px;
  border-top: 10px solid #ffa500;
  border-left: 10px solid transparent;
}
.jiantou {
  position: absolute;
  right: 0;
  bottom: 16px;
  cursor: pointer;
  svg{
    width: 16px;
    height: 16px;
  }
}
.popover {
  width: 308px;
  height: 155.5px;
  background: #ffffff;
  border: 1px solid #dddbda;
  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
  border-radius: 3px 0 0 0 3px 3px 3px;
  border-radius: 3px 0px 0px 0px 3px;
  padding: 36px;
  p {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    margin: 20px;
    justify-content: flex-start;
    span {
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      margin-left: 18px;
    }
  }
}
.nname {
  text-align: left;
}
</style>