import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

//编辑业务机会预测
export function getForecastEditTotal(data) {
    return axios.post('/customForecast/editTotal', data)
}
//保存总计
export function getForecastSaveTotal(data) {
    return axios.post('/customForecast/saveTotal', data)
}
//业务机会预测选项卡查询
export function getForecastQueryTabList(data) {
    return axios.post('/customForecast/queryTabList', data)
}
//历史跟踪
export function getForecastViewHistory(data) {
    return axios.post('/customForecast/viewHistory', data)
}
//预测详情信息
export function getForecastViewDetail(data) {
    return axios.post('/customForecast/viewDetail', data)
}
//保存预测
export function getForecastSubmit(data) {
    return axios.post('/customForecast/submit', data)
}
//编辑业务机会预测
export function getForecastEditOpp(data) {
    return axios.post('/customForecast/submit', data)
}
//保存业务机会预测
export function getForecastSaveOpp(data) {
    return axios.post('/customForecast/submit', data)
}
//业务机会预测查询
export function getViewForecast(data) {
    return axios.post('/customForecast/viewForecast', data)
}

//查询预测设置
export function getForecastSet(data) {
    return axios.post('customForecast/getforecast', data)
}
//查询用户
export function getLookupResults(data) {
    return axios.post('/customForecast/lookupResults', data)
}
//获取视图列表
export function getviewlist(objId) {
    return axios.post('/view/list/getViewList', objId)
}
//获取视图列表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}
//所有产品系列业务机会预测查询
export function viewForecastAllCpxl(data) {
    return axios.post('/customForecast/viewForecastAllCpxl', data)
}

//获取人员详细信息
export function userMessage(data) {
    return axios.post('/ccchat/person/getChatUserInfo', data)
}

//查询用户预测权限
export function getForecastPermission(data) {
    return axios.post('/customForecast/getForecastPermission', data)
}

//下载目标预测帮助手册
export function downloadManual(data) {
    return axios.get('/customForecast/downloadManual', data, 'blob')
}
// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
  }