<template>
  <!-- 底部表单列表 -->
  <div class="form_bottom">
    <!-- 标题行 -->
    <div class="brief">
      <div class="msg">
        <span>{{ $t("label.related.organization") }}</span>
      </div>
      <div class="arrows">
        <svg aria-hidden="true" @click="down" v-show="downn">
          <use xlink:href="#icon-more"></use>
        </svg>
        <svg aria-hidden="true" @click="up" v-show="upp">
          <use xlink:href="#icon-more"></use>
        </svg>
      </div>
    </div>
    <!-- 底部表格 -->
    <!-- <el-table
      ref="forecastBottom"
      :data="businessList"
      :height="tableHeight"
      :header-cell-style="{ background: '#FAFAF9' }"
      border
      style="width: 100%"
      v-loading="loadings"
      v-if="!tableDet"
    >
      <el-table-column
        v-for="(item, index) in tableHeader"
        :key="index"
        :label="item.nameLabel"
      >
        <template slot-scope="scope">
          <div v-if="item.datafieldRef == 'name'">
            <a :href="nameHrefUrl(scope.row)">{{ scope.row.name }}</a>
          </div>
          <div v-if="item.datafieldRef == 's_lookup_field1'">
            <a :href="nameHrefUrll(scope.row)">{{ scope.row.khmcccname }}</a>
          </div>
          <div v-if="item.datafieldRef == 's_str_field2'">
            <p>{{ scope.row.jine }}</p>
          </div>
          <div v-if="item.datafieldRef == 's_date_field1'">
            <p>{{ scope.row.jsrq }}</p>
          </div>
          <div v-if="item.datafieldRef == 's_str_field3'">
            <p>{{ scope.row.jieduan }}</p>
          </div>
          <div v-if="item.datafieldRef == 's_str_field5'">
            <p>{{ scope.row.forecasttype }}</p>
          </div>
          <div v-if="item.datafieldRef == 'ownerid'">
            <p>{{ scope.row.owneridccname }}</p>
          </div>
        </template>
      </el-table-column>
    </el-table> -->

    <!-- 底部表格详情信息(需要替换新表格) -->
    <div :style="{height: tableHeight + 'px'}">
      <tablePane2
        v-if="tableDet"
        :tableAttr="tableHeader"
        :tableData="tableDetail"
        :height="tableHeight"
        objectApi="Opportunity"
        border
        :pictLoading="loadings"
        :isLoadScroll="true"
        @loadScroll="loadScroll"
      />
    </div>
  </div>
</template>

<script>
import tablePane2 from "@/components/TablePanel2/index.vue";
export default {
  components: {
    tablePane2,
  },
  props: {
    foreuser: {
      type: String,
      default: "",
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
    businessList: {
      type: Array,
      default: () => [],
    },
    loadings: {
      type: Boolean,
      default: false,
    },
    tableDet: {
      type: Boolean,
      default: false,
    },
    tableDetail: {
      type: Array,
      default: () => [],
    },
    headerType: {
      type: String,
      default: "",
    },
    foreperiod: {
      type: String,
      default: "",
    },
    foreyear: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      downn: true, //调整箭头
      upp: true, //调整箭头
      tableHeight: "",
      tableDetailHeader: this.tableHeader,
      typee: "",
      ydate: "",
      lastPixelRatio: "",
    };
  },
  mounted() {
    //监测浏览器是否进行缩放
    this.lastPixelRatio = window.devicePixelRatio;
    window.addEventListener("resize", this.windowResizeFn);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
  },
  watch: {
    foreperiod() {
    },
    tableHeader() {
    },
    tableDetail: function () {
    },
    headerType: function () {
    },
    tableHeight: {
      handler(val) {
        if (val < 0) {
          this.tableHeight = 39;
        }
        //将下部分表格高度传到上部分表格组件中
        this.$Bus.$emit("bottom-table-height", val + 30);
      },
      deep: true,
    },
  },
  created() {
    this.Thighy();
  },
  methods: {
    //监测浏览器是否进行缩放
    windowResizeFn() {
      let currentPixelRatio = window.devicePixelRatio;
      //当浏览器缩放重新计算表格高度
      if (currentPixelRatio !== this.lastPixelRatio) {
        this.$Bus.$emit("bottom-table-height", this.tableHeight + 30);
      }
    },
    /**
     * 通知父组件滚动展示更多
     */
    loadScroll(){
      this.$emit('loadScroll')
    },
    Thighy() {
      this.tableHeight = document.body.offsetHeight / 3;
      this.businessList &&
        this.businessList.forEach((item) => {
          item.width = "calc((100% - 210px) / 5)";
        });
    },
    //底部表格向上展开
    up() {
      if (this.upp && this.downn) {
        // 默认 --> 完全展开
        this.tableHeight = document.body.offsetHeight / 2;
        this.upp = false;
        this.downn = true;
        return;
      }
      if (this.upp && !this.downn) {
        // 完全收起 --> 默认
        this.tableHeight = document.body.offsetHeight / 3;
        this.upp = true;
        this.downn = true;
        return;
      }
    },
    //底部表格向下收起
    down() {
      if (this.upp && this.downn) {
        // 默认 --> 完全收起
        // this.tableHeight = window.outerHeight - window.innerHeight;
        this.tableHeight = 39;
        this.upp = true;
        this.downn = false;
        return;
      }
      if (this.downn && !this.upp) {
        // 完全展开 --> 默认
        this.tableHeight = document.body.offsetHeight / 3;
        this.upp = true;
        this.downn = true;
        return;
      }
    },
    //跳转业务机会详情
    nameHrefUrl(row) {
      return row.id ? `#/commonObjects/detail/${row.id}/DETAIL` : "";
    },
    //跳转客户详情
    nameHrefUrll(row) {
      return row.khmc ? `#/commonObjects/detail/${row.khmc}/DETAIL` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table::before {
  height: 0px;
}

img {
  width: 13.7px;
  height: 13.7px;
}
a {
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
.form_bottom {
  width: calc(100% - 71px);
  position: fixed;
  bottom: 10px;
  z-index: 999;
}
::v-deep .el-table:before {
  //去掉最下面的那一条线
  height: 0px;
}
::v-deep .el-table {
  thead {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #3e3e3c;
    letter-spacing: 0;
    background: #fafaf9;
    border: 1px solid #dedcda;
  }
  tr,
  td {
    background: #fff;
    border-right: 0;
  }
}

.brief {
  border: 1px solid #dedcda;
  height: 30px;
  background: #f5f5f5;
  font-family: SourceHanSansCN-Medium;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  padding: 5px 8px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  .arrows {
    svg {
      right: 10px;
      width: 22.6px;
      height: 18.7px;
      margin-left: 10px;
      cursor: pointer;
    }
    svg:nth-child(2) {
      transform: rotate(180deg);
    }
  }
}
</style>