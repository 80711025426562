<!-- 设置预测范围弹框 -->
<template>
  <div ref="scopeFore">
    <el-dialog
      :title="$t('label_set_ forecast_scope')"
      width="500px"
      :visible.sync="scopeForeToProps"
      :before-close="scopeForeCancel"
      :modal-append-to-body="false"
      v-if="setfore"
    >
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item :label="$t('label_starting_annual')">
          <el-select
            v-model="form.nowForeyear"
            :placeholder="`第${foreyear}会计年度`"
          >
            <el-option
              v-for="item in qdyearlist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('label_starting_month')">
          <el-select
            v-model="form.nowForemon"
            :placeholder="`${foremon}月`"
          >
            <el-option
              v-for="item in months"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('label.forecast.edit.fwcd')">
          <el-select
            v-model="form.nowForeper"
            :placeholder="`${foreper}个周期`"
          >
            <el-option
              v-for="item in days"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scopeForeCancel">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button type="primary" @click="scopeForeConfirm">{{
          $t("label.save")
        }}</el-button>
      </span>
    </el-dialog>
     <el-dialog
      :title="$t('label_set_ forecast_scope')"
      width="30%"
      :visible.sync="scopeForeToProps"
      :before-close="scopeForeCancel"
      :modal-append-to-body="false"
      v-if="!setfore"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item :label="$t('label_starting_annual')">
          <el-select
            v-model="form.nowForeyear"
            :placeholder="`第${foreyear}会计年度`"
          >
            <el-option
              v-for="item in qdyearlist"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('label.starting.quarter')">
          <el-select
            v-model="form.nowForemon"
            :placeholder="`${foremon}月`"
          >
            <el-option
              v-for="item in quarter"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('label.forecast.edit.fwcd')">
          <el-select
            v-model="form.nowForeper"
            :placeholder="`${foreper}个周期`"
          >
            <el-option
              v-for="item in days"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scopeForeCancel">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button type="primary" @click="scopeForeConfirm">{{
          $t("label.save")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    scopeForeTo: {
      // 弹框显示隐藏
      type: Boolean,
      default: false,
    },
    foreyear: {
      type: String,
      default: "",
    },
    foremon: {
      type: String,
      default: "",
    },
    foreper: {
      tyle: String,
      default: "",
    },
    qdyearlist:{
      type: Array,
      default: () => [],
    },
    setfore:{
      typeof:Boolean,
    }
  },
  data() {
    return {
      scopeForeToProps:this.scopeForeTo,
       quarter: [
        {
          value: "1",
          label: this.$i18n.t("c1384",{n:1}),
        },
        {
          value: "2",
          label: this.$i18n.t("c1384",{n:2}),
        },
        {
          value: "3",
          label: this.$i18n.t("c1384",{n:3}),
        },
        {
          value: "4",
          label: this.$i18n.t("c1384",{n:4}),
        }
      ],
      months: [
        {
          value: "1",
          label: `january`,
        },
        {
          value: "2",
          label: `february`,
        },
        {
          value: "3",
          label: "march",
        },
        {
          value: "4",
          label: "april",
        },
        {
          value: "5",
          label: "mary",
        },
        {
          value: "6",
          label: "june",
        },
            {
          value: "7",
          label: "july",
        },
        {
          value: "8",
          label: "augest",
        },
        {
          value: "9",
          label: "september",
        },
        {
          value: "10",
          label: "october",
        },
        {
          value: "11",
          label: "november",
        },
        {
          value: "12",
          label: "december",
        },
      ],
      days: [
        {
          value: "0",
          label: this.$i18n.t("c1383",{n:1}),
        },
        {
          value: "1",
          label: this.$i18n.t("c1383",{n:2}),
        },
        {
          value: "2",
          label: this.$i18n.t("c1383",{n:3}),
        },
        {
          value: "3",
          label: this.$i18n.t("c1383",{n:4}),
        },
        {
          value: "4",
          label: this.$i18n.t("c1383",{n:5}),
        },
          {
          value: "5",
          label: this.$i18n.t("c1383",{n:6}),
        },
      ],
   
      form: {
      nowForeyear: "",
      nowForemon: "",
      nowForeper: "",
      },
    };
  },
  watch:{
    foreyear(){
     this.$nextTick(()=>{
     })
    },
    scopeForeTo(newVal){
      this.scopeForeToProps=newVal
    }
  },
  created() {
    this.preSettings()
  },
  methods: {
    preSettings(){
      this.form.nowForeyear= this.$i18n.t("label.multilang.forcast.financialyear",{0:this.foreyear});
      this.form.nowForemon=this.foremon;
      this.form.nowForeper= this.foreper;
    },
    scopeForeConfirm(){
        if(isNaN(this.form.nowForeyear))
        {
         this.form.nowForeyear=parseInt(this.form.nowForeyear.substring(1))
        }else{
         this.form.nowForeyear
        }
        this.$emit("scopeForeConfirm",this.form)
     },
    scopeForeCancel() {
      this.$emit("scopeForeCancel");
      this.preSettings()
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__footer {
  border-top: none;
}
.date_selection {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  span {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
  }
}
::v-deep .el-input__inner {
  width: 315px;
  height: 30px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}
.el-select {
  width: 314px;
  height: 30px;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: #080707;
}
::v-deep .el-select-dropdown__list {
   height: 182px;
}

</style>