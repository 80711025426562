<template>
  <component :is="componentName" :scopeObj="scopeObj" :row="row" :column="column" @editCell="editCell"></component>
</template>

<script>
/**
 * 表格单元格组件:后期需要什么单元格再添加
 */
import textCell from './cellType/textCell.vue';
import linkCell from './cellType/linkCell.vue'
export default {
  components:{
    textCell,
    linkCell
  },
  props:{
    // 当前scope
    scopeObj: {
        type: Object,
        default: ()=>({})
    },
    // 当前行数据
    row:{
      type:Object,
      default:()=>({})
    },
    // 当前列数据
    column:{
      type:Object,
      default:()=>({})
    }
  },
    data(){
        return {
        }
    },
    computed:{
      /**
       * componentName:根据column判断展示的组件
       */
      componentName(){
        let name='textCell'
        // 普通文本类型
        if(!this.column.click &&!this.column.options &&!this.column.isInput &&!this.column.ishtml){
          name='textCell'
        }
        // a标签类型,判断类型后期需要再加
         if(this.isLink()){
          name='linkCell'
        }
        return name
      },
    },
    methods:{
      /**
       * 判断是否可跳转
       */
      isLink(){
        // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          // 定义可以跳转的字段
          let linkObj={
            apiname:["name","subject","whoid","relateid"],
            type:["Y","M","MR"]
          }
          return linkObj.apiname.includes(this.column.apiname)||linkObj.type.includes(this.column.type)
      },
     /**
     * 通知父组件内联编辑
     */
      editCell(column,row){
          this.$emit('editCell',column,row)
      },
    }
}
</script>

<style>

</style>