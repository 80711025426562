<template>
  <span
    :style="{
      'text-align':
        (column.schemefieldType === 'N' &&
          column.schemefieldName !== 'leadscore') ||
        column.schemefieldType === 'c' ||
        column.schemefieldType === 'P' ||
        (column.schemefieldType === 'Z' &&
          (column.expressionType == 'N' ||
            column.expressionType == 'P' ||
            column.expressionType == 'c')) ||
        column.schemefieldType === 'C'
          ? 'right'
          : 'left',
    }"
  >
    <!-- 日期 -->
    <span class="cannotEdit" v-if="column.schemefieldType === 'D'">
      {{ row[column.schemefieldName] }}
    </span>
    <!-- 日期时间 -->
    <span class="cannotEdit" v-else-if="column.schemefieldType === 'F'">
      {{ row[column.schemefieldName]}}
    </span>
    <!-- 记录类型 -->
    <span class="cannotEdit" v-else-if="ccnameAry.includes(column.schemefieldType)">
      {{ row[`${column.schemefieldName}ccname`] }}
    </span>

    <!-- 复选框 -->
    <span class="cannotEdit" v-else-if="column.schemefieldType === 'B'">
      <el-checkbox
        :value="
          row[column.schemefieldName] === 'true' ||
          row[column.schemefieldName] === '1'
        "
        disabled
      >
      </el-checkbox>
    </span>
    <!-- URL -->
    <span
      class="cursor-pointer allow-click"
      v-else-if="column.schemefieldType === 'U'"
      @click="jumpToUrl(row[column.schemefieldName])"
    >
      {{ row[column.schemefieldName] }}
    </span>
    <!-- 标签 -->
    <span
      class="cannotEdit"
      v-else-if="
        column.schemefieldType === 'X' && column.schemefieldName === 'cloudcctag'
      "
    >
      <el-tooltip
        :content="
          row['cloudcctagList']
            ? row['cloudcctagList'].map((res) => res.name).join(' ')
            : ''
        "
        placement="top-start"
        :visible-arrow="false"
      >
        <span>
          <el-tag
            v-for="tag in row['cloudcctagList']"
            :key="tag.id"
            :type="
              tag.color === 'hui'
                ? 'info'
                : tag.color === 'hong'
                ? 'danger'
                : tag.color === 'lv'
                ? 'success'
                : tag.color === 'lan'
                ? ''
                : tag.color === 'huang'
                ? 'warning'
                : ''
            "
            effect="dark"
            class="tag"
          >
            {{ tag.name }}
          </el-tag>
        </span>
      </el-tooltip>
    </span>
    <!-- 图片 -->
    <span
      v-else-if="
        column.schemefieldType === 'IMG' && column.expressionType !== 'url'
      "
      style="width: 100%; display: inlne-block"
    >
      <el-popover placement="right" title="" trigger="hover">
        <img
          :src="imgSrc(row[column.schemefieldName])"
          style="max-height: 200px; maxwidth: 200px"
        />

        <img
          v-if="
            row[column.schemefieldName] !== undefined &&
            row[column.schemefieldName] !== '' &&
            row[column.schemefieldName] !== null
          "
          slot="reference"
          :src="imgSrc(row[column.schemefieldName])"
          :alt="imgSrc(row[column.schemefieldName])"
          style="height: 16px; width: 16px"
        />
      </el-popover>
    </span>
    <!-- 图片 引用类型 -->
    <span
      v-else-if="
        column.schemefieldType === 'IMG' && column.expressionType === 'url'
      "
    >
      <el-popover placement="right" title="" trigger="hover">
        <img
          :src="row[column.schemefieldName]"
          style="max-height: 200px; max-width: 200px"
        />
        <img
          v-if="
            row[column.schemefieldName] !== undefined &&
            row[column.schemefieldName] !== '' &&
            row[column.schemefieldName] !== null
          "
          slot="reference"
          :src="row[column.schemefieldName]"
          :alt="row[column.schemefieldName]"
          style="height: 16px; width: 16px"
        />
      </el-popover>
    </span>
    <!-- 评分 -->
    <span v-else-if="column.schemefieldType === 'SCORE'" class="cannotEdit stars">
      <span
        v-for="(star, idx) in Number(column.schemefieldLength) + 1"
        v-show="idx !== 0"
        :class="{
          show2: idx <= Number(row[column.schemefieldName]),
        }"
        :key="idx"
      >
      </span>
    </span>
    <!-- 处理富文本展示 -->
    <span
      v-else-if="column.schemefieldType == 'Z' && column.expressionType == 'S'"
      class="cannotEdit"
      v-html="row[column.schemefieldName]"
    >
    </span>
    <!-- 普通文本 -->
    <span class="cannotEdit" v-else>
      {{ row[column.schemefieldName] }}
    </span>
    <!--事件及任务下的名称及相关项去除编辑/相关列表文件、类型attachtype去除编辑-->
    <svg
      v-if="
        column.schemefieldName !== 'relateobj' &&
        column.schemefieldName !== 'whoobj' &&
        column.schfemefieldName !== 'attachtype'
      "
      class="icon editIconImg"
      aria-hidden="true"
      style="display: none"
      @click="editCell(column, row)"
    >
      <use href="#icon-pen"></use>
    </svg>
  </span>
</template>

<script>
// import { getCookie } from "@/utils/brower";
export default {
  props: {
    // 当前行数据
    row: {
      type: Object,
      default: () => ({}),
    },
    // 当前列数据
    column: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
        // token
        token:this.$CCDK.CCToken.getToken(),
        // 使用加ccname渲染label的字段类型
        ccnameAry:['R','Y'],
    };
  },
  methods:{
    /**
     * 通知父组件内联编辑
     */
    editCell(column,row){
        this.$emit('editCell',column,row)
    },
    /**
     * 普通文本中url跳转
     * @param {String} url:跳转地址
     */
    jumpToUrl(url) {
      if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    /**
     * 处理img地址
     * @param {String} id:scope.row[item.schemefieldName]
     */
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  }
};
</script>

<style lang="scss" scoped>
// 评分
.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}
</style>