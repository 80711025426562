<template>
  <div id="table-panel2">
    <div class="demoBox"></div>
    <!-- 表格组件 -->
    <div class="content-main" v-loading="pictLoading">
      <el-table
        ref="tablePanel"
        id="table"
        :data="tableData"
        :height="height"
        :border="border"
        :isLoadScroll="isLoadScroll"
        :loadScroll="loadScroll"
        @cell-mouse-enter="cellMouseEnter"
        @cell-mouse-leave="cellMouseLeave"
        @cell-click="changLine"
        @cell-dblclick="cellDoubleClick"
        :empty-text="$t('label.dashboard.noRecord')"
        :style="{
          width: '100%',
          'min-height': minHeight ? '418px' : '0px',
        }"
      >
        <!-- 表头&内容展示区 -->
        <template v-for="item in tableAttr">
          <slot :name="item.schemefieldName" :item="item">
            <el-table-column
              :label="item.nameLabel"
              :prop="item.schemefieldName"
              :show-overflow-tooltip="true"
            >
            <!-- 自定义头部 -->
             <template #header v-if="customHeader">
                    <!-- 自定义表头信息 -->
                    <!-- 暂时先不需要，等需要了再添加 -->
                    <tableHeader/>
                  </template>
            <!-- 单元格 -->
              <template slot-scope="scope">
                <tableCell :scopeObj="scope" :row="scope.row" :column="item" @editCell="editCell"/>
              </template>
            </el-table-column>
          </slot>
        </template>
        <slot></slot>
      </el-table>
    </div>
      <!-- 表格内联编辑浮窗 -->
      <div class="lineBox" v-show="dialogVisible" ref="lineBox">
        <div class="editBox">
          <editable-cell
            ref="editableCell"
            :field="field"
            :schemefieldLength="schemefieldLength"
            :options="editableCellOptions"
            :value="toChangeValue"
            :isFilter="false"
            :input-type="inputType"
            :err-tip="errTip"
            :precision="precision"
            :min="min"
            :max="max"
            origin-type="table"
            width="calc(100% - 52px)"
            @remoteSearch="remoteSearch"
          >
          </editable-cell>
          <div>
            <!-- 保存 -->
            <svg
              class="icon lineBoxEdit"
              aria-hidden="true"
              @click="saveChange"
            >
              <use href="#icon-Hook"></use>
            </svg>
            <!-- 取消 -->
            <svg
              class="icon lineBoxEdit"
              aria-hidden="true"
              @click="handleClose"
            >
              <use href="#icon-fork"></use>
            </svg>
          </div>
        </div>
        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others && checkedList.length > 1"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
      </div>
      <!--  -->

      <!-- 表格内联编辑弹窗 -->
      <div class="dialogModel">
        <el-dialog
          top="0"
          width="0%"
          :modal="false"
          :show-close="false"
          :visible.sync="dialogVisible"
          :before-close="handleClose"
        >
        </el-dialog>
      </div>
            <!-- 内联编辑查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="beforeClose"
        append-to-body
      >
        <div
          :style="{
            'min-height': dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <search-table
            ref="searchTable"
            :dialogBodyHeight="dialogBodyHeight"
            :fieldId="relevantFieldId"
            :checked="multiChecked"
            :relevant-objid="relevantObjId"
            :relevant-objapi="relevantObjApi"
            :relevant-prefix="relevantPrefix"
            :projectId="projectId"
            :optionCheckedArr="optionCheckedArr"
            @changeSelect="changeSelect"
            @setFieldReltaion="setFieldReltaion"
          />
        </div>
      </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * 新版列表表格组件2
 * 使用场景：页面动态表格展示使用
 * 功能：
 * 1、动态渲染表格
 * 2、表格行内编辑
 * 3、滚动加载更多
 * 4、跳转详情
 */
// import { getCookie } from "@/utils/brower";
import EditableCell from "@/views/List/TablePanel/EditableCell.vue";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api.js";
import tableCell from './tableCell.vue';
import tableHeader from './tableHeader.vue'


export default {
  components: {
    EditableCell,
    tableCell,
    tableHeader
  },
  props: {
    // 对象api
    objectApi: {
      type:String,
      default:''
    },
    height: {
      type: Number,
      default: 300,
    },
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: false,
    },
    // 是否开启内联编辑
    enableInlineEdit: {
      type: Boolean,
      default: true,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 表头数据
    tableAttr: {
      type: Array,
      default: () => [],
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },

    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 是否开启加载表格更多功能
    isLoadScroll: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // 表格选中的数据
      checkedList:[],
      schemefieldLength: 0,
      // 定时器
      timeOutClear: -1,
      // 定时器变量
      timeOut: -1,

      // 查找多选弹窗宽度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      // 查找多选弹窗
      showSearchTable: false,
      // 查找、主详、查找多选字段，查找弹窗组件使用，选中
      multiChecked: false,
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantFieldId: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantObjId: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantObjApi: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantPrefix: "",
      // 查找多选
      optionCheckedArr: [],
      // 表格展示的数据
      dataList: [],
      // 表格选中的数据（需要判断下和editableCellOptions是否可以共用一个）

      // 表头的name数组
      nameLableList: [],

      // 内联编辑提示
      errTip: this.$i18n.t("label.vlidaterule.message"),
      // 内联编辑要修改的字段名称
      field: "",
      // 内联编辑要修改的字段ApiName
      fieldApiName: "",
      // 内联编辑是否应用于全部选中数据
      changeAll: false,
      // 内联编辑时，控制是否可以全部应用于选中数据展示
      showApply2Others: false,
      // 内联编辑所有选中的数据集合（应该能和表格选中数据集合用同一个变量）
      editableCellOptions: [],
      // 内联编辑要修改数据显示类型
      inputType: "",
      // 内联编辑要修改的数据
      toChangeValue: null,
      // 内联编辑弹窗
      dialogVisible: false,
      //   pin: false, // 是否按住shift键（表格shift多选功能后期要加上）
      //单元格编辑字段信息
      editableCellAttr: null,
      // 编辑单元格时的rowId
      dataId: "",
      // 内联编辑时当前记录的数据，用于查找筛选条件匹配
      rowData: {},

      // 编辑单元格数字时的小数位
      precision: 0,
      // 编辑单元格数字时的最小值
      min: 0,
      // 编辑单元格数字时的最大值
      max: 0,
      inlineedit: false, // 全局内联编辑权限
      // 管理员权限
      objModifyAll: null,
      // 对象编辑权限
      objModify: null,
      // 对象删除权限
      objDelete: null,
      // token
      token: this.$CCDK.CCToken.getToken(),
      // 管理列时选中的表头id数据
      str: "",
      // 管理列图标
      DropChangeBoll: false,
      // 项目id
      projectId: "",
      // 文件预览参数
      showPreview: false,
      // 服务报告预览数据
      showPreviewData: {},
      // 服务报告预览url
      previewUrl: "",
      //保存加载状态
      isSaveBtnLoading: false,
      //tbody局部 loading
      jubuloading: null,
      // 所有人宽
      headWidth: "23px",
      // 所有人高
      headHeight: "23px",
      // 列表页内联编辑是否切换价格手册弹框
      pricIntro: false,
      // 价格手册行数据
      priceRow: [],
      // 修改价格手册是否触发确定按钮
      priceIsDelSon: false,
      // 记录id
      recordId: "",
      // 不能强制修改props值
      tableButtonData: this.tableButton,
 
    };
  },
  created() {
    // 更新全局内联编辑权限
    this.inlineedit = localStorage.getItem("inlineedit")&&this.enableInlineEdit;
  },
  methods: {
    /**
     * loadScroll:表格滚动加载更多
     */
    loadScroll(){
      this.$emit('loadScroll')
    },
    /**
     * 内联编辑查找弹窗关闭之前事件
     */
    beforeClose(){
      this.showSearchTable=false
    },

    // 查找/查找多选
    remoteSearch(val) {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
        let checkoutList = [...this.editableCellOptions].filter((x) =>
          [...val].some((y) => x.value === y)
        ); // 取并集
        let option = [];
        checkoutList.forEach((res) => {
          option.push({
            id: res.value,
            name: res.label,
          });
        });
        this.optionCheckedArr = option;
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      this.priceRow = row;
      let options = [];
      let optionValue = [];
      this.optionCheckedArr = [];
      this.showSearchTable = false;

      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
        this.optionCheckedArr = row;
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue.push(row.data.id);
        optionValue = optionValue.toString();
        this.optionCheckedArr.push(row.data);
      }
      if (
        row.data &&
        row.field.name == "价格手册名称" &&
        (this.objectApi === "cloudccorder" ||
          this.objectApi === "quote" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccpurchaseorder" ||
          this.objectApi === "Opportunity")
      ) {
        /**
         * 订单、报价单、发票、采购订单、业务机会等对象（列表页内联编辑）修改价格手册时，需查看相关子产品是否有数据，
         * 有则给出提示语，否则直接修改价格手册
         * @param {String} objId 对象id
         * @param {String} recordId 相关记录id
         */
        let params = {
          objId: this.objId,
          recordId: this.recordId,
        };
        CommonObjApi.existRelatedProductInfo(params).then((res) => {
          if (res.data == "true") {
            this.pricIntro = true;
          } else {
            this.editableCellOptions = options;
            this.toChangeValue = { value: optionValue };
          }
        });
      } else {
        this.editableCellOptions = options;
        this.toChangeValue = { value: optionValue };
      }
    },

    /**
     * 内联编辑查找字段时，设置查找筛选条件。
     * 注意：筛选条件如果设置field才需要前端传值，设置的筛选条件字段必须在表格列中出现才有效
     * @param {Array} filterFieldIds:设置筛选条件的数组，包含条件对应的字段id和字段类型
     */
    setFieldReltaion(filterFieldIds) {
      let values = [""];
      if (Array.isArray(filterFieldIds) && filterFieldIds.lenght > 0) {
        values.push(this.dataId);
        let attrMap = new Map();
        // 表头数据,生成字段id和apiname的map
        this.tableAttr.forEach((item) => {
          attrMap.set(item.id, item.schemefieldName);
        });
        // 找到对应筛选条件的值
        filterFieldIds.forEach((item) => {
          // 根据字段id获的该字段的props
          let fieldProps = attrMap.get(item.fieldid);
          if (fieldProps) {
            // 该字段的被设置了筛选条件，将值放入筛选条件查询参数中
            let val = this.rowData[fieldProps] || "";
            values.push(val);
          } else {
            // 即使没有值也要存空字符串，必须保证顺序
            values.push("");
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },

    // 节流函数
    jieliu(fn, delay) {
      if (this.timeOut !== -1) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        fn();
        this.timeOut = -1;
      }, delay);
    },
    // 内联编辑时做的事
    enterFun(row, column, cell) {
      this.recordId = row.id; // 记录id
      cell.getElementsByClassName("editIconImg")[0]
              ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                  "")
              : "";
      
      
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell, event) {
      // 判断是否开启内联编辑
      if (!this.isCanInlineedit(column.property,this.objectApi)) return;
      // 清空上次修改rowid的定时器
      clearTimeout(this.timeOutClear);
      this.jieliu(() => {
        this.enterFun(row, column, cell, event);
      }, 200);
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell) {
      // 判断是否开启内联编辑
      if (!this.isCanInlineedit(column.property,this.objectApi)) return;
      // 清空当前rowid
      if (this.timeOutClear !== -1) {
        clearTimeout(this.timeOutClear);
      }
      this.timeOutClear = setTimeout(() => {
        //  防止最后一个小笔不消失
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }, 500);

      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },

    // 表格拖动列时触发的操作
    headerDragend(newWidth, oldWidth, column) {
      this.columns[column.property] = newWidth;
      // 判断newWidth是否小于最小宽度，如果小于，则值为最小宽度，如果未设置最小宽度，需要对比newWidth是否小于默认的minWidth，如果小于，则宽度为默认的minwidth
      if (column.minWidth && column.minWidth > newWidth) {
        column.width = column.minWidth;
        this.columns[column.property] = column.minWidth;
        // `${column.label}最小宽度为${column.minWidth}px`
        this.$message.warning(
          this.$i18n.t("c955", {
            label: column.label,
            minWidth: column.minWidth,
          })
        );
      } else if (newWidth < this.minWidth) {
        column.width = this.minWidth;
        this.columns[column.property] = this.minWidth;
      }
      // 只改变拖动列的宽度
      this.$refs.tablePanel.columns.forEach((item) => {
        if (!item.width) {
          item.width = this.columns[item.property];
        }
      });
      let fieldId = "";
      this.tableAttr.forEach((attr) => {
        if (attr.schemefieldName === column.property) {
          fieldId = attr.id;
        }
      });
      let params = {
        viewId: this.viewId,
        fieldId: fieldId,
        colwidth: Math.round(newWidth),
      };
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        params.viewId = `${this.prefix}_${this.viewId}`;
      }
      if (this.viewId !== "") {
        CommonObjApi.updateViewFieldMemory(JSON.stringify(params)).then(() => {
          // 更新表格滚动条
          this.$refs.yiScrollbar.initV();
        });
      }
    },

    // 点击单元格触发，获取所在单元格位置
    changLine(row, column, cell) {
      let cellObj = cell.getBoundingClientRect();
      let lineBoxLeft =
        window.innerWidth - cellObj.left > 240
          ? cellObj.left
          : window.innerWidth - 240;
      let lineBoxTop =
        window.innerHeight - cellObj.top > 64
          ? cellObj.top
          : window.innerHeight - 64;
      this.$refs.lineBox.style.top = lineBoxTop + "px";
      this.$refs.lineBox.style.left = lineBoxLeft + "px";
      // 弹窗默认宽度240px，如果当前单元格宽度大于240px，则展示当前单元格的宽度
      if (cellObj.width > 240) {
        this.$refs.lineBox.style.width = cellObj.width + "px";
      } else {
        this.$refs.lineBox.style.width = "240px";
      }
    },
    // 编辑单元格
    editCell(item, row) {
      //项目管理行内编辑根据项目对里程碑、任务、成员做限制
      this.projectId = row.project_name || row.their_project;
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();

      // 根据Id获取数据权限
      CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
        if (res.data.isEdit) {
          // 点击单元格内编辑图标时不跳转
          this.dataId = row.id;
          this.rowData = Object.assign({}, row);
          this.changeAll = false;
          this.editableCellOptions = [];
          this.inputType = INPUTTYPE[item.schemefieldType];
          this.showApply2Others =
            item.schemefieldType == "X" || item.schemefieldType == "MR"
              ? false
              : true;
          // 列表点击日期编辑处理
          if (item.type === "D") {
            this.toChangeValue = row[item.schemefieldName]
              ? { value: new Date(row[item.schemefieldName]) }
              : "";
          } else {
            this.toChangeValue = { value: row[item.schemefieldName] };
          }
          this.editableCellAttr = item;
          // 复选框类型无返回值时
          if (item.schemefieldType === "B") {
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined
                  ? false
                  : row[item.schemefieldName] === "true"
                  ? "true"
                  : "false",
            };
          } else if (
            item.schemefieldType === "X" &&
            item.apiname === "cloudcctag"
          ) {
            // 标签
            this.inputType = "tag";
            this.toChangeValue = { value: row["cloudcctagList"] };
          }
          // 查找、主详、查找多选字段
          else if (
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.relevantFieldId = item.id;
            this.relevantObjId = item.lookupObj;
            this.multiChecked = item.schemefieldType === "MR" ? true : false;
            this.editableCellOptions = [];
            if (
              row[item.schemefieldName] &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType !== "MR"
            ) {
              this.editableCellOptions = [
                {
                  label: row[`${item.schemefieldName}ccname`],
                  value: row[item.schemefieldName],
                },
              ];
            } else if (
              row[item.schemefieldName] &&
              row[item.schemefieldName] !== "" &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType === "MR"
            ) {
              let values = row[item.schemefieldName].split(";");
              let labels = row[`${item.schemefieldName}ccname`].split(";");
              values.forEach((item, idx) => {
                this.editableCellOptions.push({
                  label: labels[idx],
                  value: values[idx],
                });
              });
              this.toChangeValue = { value: values };
            }
          } else if (
            item.schemefieldType === "L" ||
            item.schemefieldType === "Q" ||
            item.schemefieldType === "ct"
          ) {
            // 选择/多选/币种类型
            this.editableCellOptions = [];
            item.options&&item.options.forEach((ele) => {
              this.editableCellOptions.push({
                label: ele.codevalue,
                value: ele.codekey,
              });
            });
          } else if (this.inputType === "number") {
            this.precision = item.decimalPlaces
              ? Number(item.decimalPlaces)
              : 0;
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 币种字段可能带币种类型，特殊处理
            if (item.schemefieldType === "c") {
              let changeValue = 0;
              if (
                row[item.schemefieldName] !== undefined &&
                row[item.schemefieldName] !== null
              ) {
                changeValue =
                  row[item.schemefieldName].indexOf("(") !== -1
                    ? row[item.schemefieldName].split("(")[0]
                    : row[item.schemefieldName];
                changeValue =
                  changeValue.indexOf(" ") !== -1
                    ? changeValue.trim().split(" ")
                    : 
                      changeValue;
              }
              this.toChangeValue = { value: changeValue };
            }
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "percent-number") {
            this.precision = Number(item.decimalPlaces);
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "score") {
            this.schemefieldLength = Number(item.schemefieldLength);
            this.toChangeValue = { value: Number(row[item.schemefieldName]) };
          }
          this.field = item.nameLabel;
          this.fieldApiName = item.apiname;
          this.dialogVisible = true;
          // this.$refs.dialogBox.style.z-index = 2;
          this.$refs.editableCell && this.$refs.editableCell.setInputValue();
        } else {
          this.$message.warning(
            // "抱歉，您无权编辑该条数据，请联系系统管理员。"
            this.$i18n.t("report_label_norole")
          );
        }
      });
    },

    /**
     * 判断内联编辑权限（前端判断部分，在请求接口权限之前）预测内联编辑(所有表格内联编辑判断统一加到这里)
     * @param {String} apiname:当前编辑字段apiname
     * @param {String} objectApi:当前objectApi
     * @return {Boolean} 是否可以内联编辑
     */
    isCanInlineedit(apiname,objectApi=""){
      if(!apiname) return false
      let flag=this.inlineedit

      // 预测下业务机会内联编辑
      if(objectApi==='Opportunity'){
        // 仅业务机会名称apiname: "name"  客户名apiname: "khmc" 结束时间apiname: "jsrq"  阶段apiname: "jieduan"可编辑
        const apinameAry=["name","khmc","jsrq","jieduan"]
        flag= flag&&apinameAry.includes(apiname)
      }
      return flag
    },
    // 表格被双击
    async cellDoubleClick(row, column) {
       // 查找要显示的数据类型
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
       // 判断预测下内联编辑是否有权限
      if(!this.isCanInlineedit(attr[0].apiname),this.objectApi) return false
      
      // 附件不需要进行权限校验
      if (
        this.$route.params.type === "file" ||
        (this.itemObj && this.itemObj.relatedlistType === "attachement")
      ) {
        return;
      }
     
      if(!attr[0]) return false
      // 事件及任务下的名称及相关项去除双击编辑
      if (
        attr[0].apiname === "relateobj" ||
        attr[0].apiname === "relateid" ||
        attr[0].apiname === "whoobj" ||
        attr[0].apiname === "whoid"
      ) {
        return false;
      }
      // 实际人工成本、预计人工成本，项目任务/问题-总是持续时长不能进行双击编辑
      if (
        
        (this.objectApi === "cloudcc_real_labor_costs" ||
          this.objectApi === "cloudccPlanCost" ||
          ((this.objectApi === "cloudccTask" ||
            this.objectApi === "CloudccProblem") &&
            attr[0].apiname === "continue_date") ||
          attr[0].apiname === "taskOrProblem" ||
          (this.objectApi === "CloudccProjectActualWorkList" &&
            attr[0].apiname === "project_name") ||
          attr[0].apiname === "their_project_name" ||
          attr[0].apiname === "project_name" ||
          attr[0].apiname === "their_project" ||
          (this.objectApi == "cloudccSubtask" &&
            attr[0].apiname === "their_milestone") ||
          attr[0].apiname === "principal" ||
          attr[0].apiname === "their_task" ||
          (this.objectApi === "cloudccSubtask" &&
            attr[0].apiname === "continue_date"))
      ) {
        return false;
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        return false;
      }
     
      // 判断对象编辑权限、字段编辑权限、记录编辑权限
      if (
        
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        this.inlineedit
      ) {
        let res = await CommonObjApi.getPermissionById({ id: row.id });
        if (!res.data.isEdit) {
          this.$message.warning(this.$i18n.t("report_label_norole"));
          return;
        }

        this.dataId = row.id;
        this.changeAll = false;
        this.editableCellOptions = [];
        this.showApply2Others = true;

        this.editableCellAttr = attr[0];
        // 图片、文件字段不可内联编辑
        if (
          attr[0].schemefieldType !== "IMG" &&
          attr[0].schemefieldType !== "FL"
        ) {
          if (attr[0].modify === "true") {
            this.inputType = INPUTTYPE[attr[0].schemefieldType];
            this.toChangeValue = { value: row[column.property] };
            // 复选框类型无返回值时
            if (
              attr[0].schemefieldType === "B" &&
              row[column.property] === undefined
            ) {
              this.toChangeValue = {
                value:
                  row[column.property] === undefined
                    ? false
                    : row[column.property] === "true",
              };
            }
            // 标签
            else if (
              attr[0].schemefieldType === "X" &&
              attr[0].apiname === "cloudcctag"
            ) {
              this.inputType = "tag";
              this.toChangeValue = { value: row["cloudcctagList"] };
            }
            // 查找、主详、查找多选字段
            else if (
              attr[0].schemefieldType === "Y" ||
              attr[0].schemefieldType === "M" ||
              attr[0].schemefieldType === "MR"
            ) {
              this.relevantFieldId = attr[0].id;
              this.relevantObjId = attr[0].lookupObj;
              this.multiChecked =
                attr[0].schemefieldType === "MR" ? true : false;
              this.editableCellOptions = [];
              if (
                row[column.property] &&
                row[`${column.property}ccname`] &&
                attr[0].schemefieldType !== "MR"
              ) {
                this.editableCellOptions = [
                  {
                    label: row[`${column.property}ccname`],
                    value: row[column.property],
                  },
                ];
              } else if (
                row[column.property] &&
                row[column.property] !== "" &&
                row[`${column.property}ccname`] &&
                attr[0].schemefieldType === "MR"
              ) {
                let values = row[column.property].split(";");
                let labels = row[`${column.property}ccname`].split(";");
                values.forEach((item, idx) => {
                  this.editableCellOptions.push({
                    label: labels[idx],
                    value: values[idx],
                  });
                });
                this.toChangeValue = { value: values };
              }
            }
            // 选项列表、选项列表多选
            else if (
              attr[0].schemefieldType === "L" ||
              attr[0].schemefieldType === "Q" ||
              attr[0].schemefieldType === "ct"
            ) {
              this.editableCellOptions = [];
              attr[0].options.forEach((item) => {
                this.editableCellOptions.push({
                  label: item.codevalue,
                  value: item.codekey,
                });
              });
            } else if (this.inputType === "number") {
              this.precision = Number(attr[0].decimalPlaces);
              this.toChangeValue = {
                value:
                  row[column.property] === undefined ||
                  row[column.property] === null
                    ? row[column.property]
                    : row[column.property].replace(/,/g, ""),
              };

              // 币种字段可能带币种类型，特殊处理
              if (attr[0].schemefieldType === "c") {
                let changeValue = 0;
                if (
                  row[column.property] !== undefined &&
                  row[column.property] !== null
                ) {
                  changeValue =
                    row[column.property].indexOf("(") !== -1
                      ? row[column.property].split("(")[0]
                      : row[column.property];
                  changeValue =
                    changeValue.indexOf(" ") !== -1
                      ? changeValue.trim().split(" ")
                      : // [
                        //   changeValue.trim().split(" ").length - 1
                        // ].replace(/,/g, "")
                        changeValue;
                }
                this.toChangeValue = { value: changeValue };
              }
              // 控制内联编辑为null时不显示0
              this.toChangeValue.value =
                this.toChangeValue.value === null
                  ? undefined
                  : this.toChangeValue.value;

              this.min =
                0 -
                Math.pow(
                  10,
                  attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                ) +
                1;
              this.max =
                Math.pow(
                  10,
                  attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                ) - 1;
            }
            this.field = attr[0].nameLabel;
            this.fieldApiName = attr[0].apiname;
            this.dialogVisible = true;
            this.$refs.editableCell && this.$refs.editableCell.setInputValue();
          }
        }
      }
    },
    // 内联编辑保存修改字段
    saveChange() {
      let fields = [];
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell.editValue;
      if (
        !value &&
        (this.editableCellAttr.apiname == "name" ||
          this.editableCellAttr.schemefieldType == "M")
      ) {
        if (this.editableCellAttr.apiname == "name") {
          // name字段必填

          this.$message.warning(this.$i18n.t("c963"));
        }
        if (this.editableCellAttr.schemefieldType == "M") {
          // 主详字段必填
          this.$message.warning(this.$i18n.t("c964"));
        }
        return;
      }
      let precision = this.$refs.editableCell.precision;
      let inputType = this.$refs.editableCell.inputType;
      //数字类型保存传值（小数位不够时补0）
      if (inputType === "number" && value) {
        value = value.toFixed(precision);
      }
      //列表行内编辑-项目管理系统合同金额
      if (this.editableCellAttr.apiname === "contract_amount") {
        let _value = value + "";
        if (_value.length > 10) {
          // 合同金额最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.contract.amount.is.10.digits"
            )
          );
          return;
        }
      }
      //列表行内编辑-项目管理系统 任务工时小时数
      if (this.editableCellAttr.apiname === "daily_working_hours") {
        let _value1 = value;
        if (_value1 > 24 || _value1 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      //列表行内编辑-实际工作清单的实际工作时长
      if (this.editableCellAttr.apiname === "working_hours_billing") {
        let _value2 = value;
        if (_value2 > 24 || _value2 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      this.tableAttr.forEach((item) => {
        // 字段apiname取apiname或者schemefieldName不定
        fields.push(item.apiname || item.schemefieldName);
      });

      if (this.checkedList.length > 1 && this.changeAll) {
        this.checkedList.forEach((item) => {
          let data = { id: item.id };
          data[
            this.editableCellAttr.apiname ||
              this.editableCellAttr.schemefieldName
          ] =
            value === null || value === undefined ? value : this.format(value);
          datas.push(data);
        });
      } else {
        let data = { id: this.dataId };
        data[
          this.editableCellAttr.apiname || this.editableCellAttr.schemefieldName
        ] = value === null || value === undefined ? value : this.format(value);
        datas.push(data);
      }
      let regEmail =
        /^([a-z0-9A-Z]*[-_]*[.]?)*@([a-z0-9A-Z]+(-[a-z0-9A-Z-]+)?\.)+[a-z0-9A-Z]{2,}$/;
      if (datas.length > 200) {
        // 最多应用于200条数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else if (
        this.fieldApiName === "twitter" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("twitter.com") === -1
      ) {
        // twitter、LinkedIn、Facebook字段加验证，必须带twitter.com、linkedin.com、facebook.com
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.twittererror")
        );
      } else if (
        this.fieldApiName === "linkedin" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("linkedin.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.linkedinerror")
        );
      } else if (
        this.fieldApiName === "facebook" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("facebook.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.facebookerror")
        );
      } else if (
        this.fieldApiName === "email" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !regEmail.test(value)
      ) {
        this.$message.warning(
          this.$i18n.t("front-kefu-moudle-v1-yxgsbzq-reinput")
        );
      } else {
        //编辑项目任务列表
        if (
          window.location.href.substring(window.location.href.length - 3) ==
          "p03"
        ) {
          // 按钮加载中
          this.isSaveBtnLoading = true;
          CommonObjApi.getPermissionById({ id: datas[0].id })
            .then((res) => {
              let params = {
                objectApi: res.data.objectApi,
                jsonstr: JSON.stringify(datas),
                fields: fields.toString(),
              };
              CommonObjApi.updateViewListAjax(params)
                .then((res) => {
                  this.isSaveBtnLoading = false;
                  res.data.updateResultList.forEach((updateResult) => {
                    if (updateResult.errorMessage) {
                      this.$message.error(updateResult.errorMessage);
                      return;
                    } else {
                      this.$message.success(
                        this.$i18n.t(
                          "label.setup.mobile.bottombutton.change.success"
                        )
                      ); //"修改成功"
                      this.$emit("refresh");
                      this.checkedList = [];
                      this.dialogVisible = false;
                    }
                  });
                })
                .catch(() => {
                  this.isSaveBtnLoading = false;
                });
            })
            .catch(() => {
              this.isSaveBtnLoading = false;
            });
        } else {
          // 更新主记录的价格手册，删除原主记录子产品
          if (this.priceIsDelSon == true) {
            let paramsY = {
              objId: this.objId,
              recordId: this.recordId,
            };
            CommonObjApi.deleteRelatedProductInfo(paramsY).then(() => {});
          }
          let params = {
            objectApi: this.objectApi,
            jsonstr: JSON.stringify(datas),
            fields: fields.toString(),
          };
          //输入值为空时不可保存
          this.isSaveBtnLoading = true;

          CommonObjApi.updateViewListAjax(params)
            .then((res) => {
              this.isSaveBtnLoading = false;
              const data = this.$refs.tablePanel.tableData;
              if (
                res.data !== null &&
                res.data.list &&
                res.data.list.length > 0
              ) {
                res.data.list.forEach((changeItem) => {
                  data.forEach((item, idx) => {
                    if (item.id === changeItem.id && item.name !== "") {
                      // 改变修改项，注意，原项中自定义的属性需要保留
                      changeItem = Object.assign(data[idx], changeItem);
                      // 增加跳转地址属性
                      this.$set(
                        this.$refs.tablePanel.tableData,
                        idx,
                        changeItem
                      );
                    }
                  });
                });
              }
              // 处理提示语
              let returnInfo = "";
              res.data.updateResultList.forEach((updateResult) => {
                if (updateResult.isSuccess !== "true") {
                  returnInfo += this.$i18n.t(
                    "vue_label_notice_batchedit_fail",
                    [updateResult.id, updateResult.errorMessage]
                  );
                }
              });
              if (returnInfo === "") {
                //群策卡片
                //
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
                // 清空选中数据
                this.checkedList = [];
                this.dialogVisible = false;
                this.$refs.tablePanel.clearSelection();
              } else {
                this.$message.error(returnInfo);
              }
            })
            .catch(() => {
              this.isSaveBtnLoading = false;
            });
        }
      }
    },
    //格式转化
    format(val) {
      if (val.constructor == Array) {
        return val.join(";");
      } else {
        return val.toString();
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
  },
  destroyed() {
    //   清空所有定时器
    clearTimeout(this.timeOutClear);
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.liBox {
  &:hover {
    color: $--color-black;
  }
}
// 表格列拖动时竖线样式
::v-deep .el-table__column-resize-proxy {
  border-left: 1.5px solid $--color-primary;
}
// 列管理器样式
.el-popper {
  padding: 0px !important;
  border: 1px solid #e1e1e1;
  .el-dropdown-menu__item {
    margin: 4px;
    border-radius: 4px 4px 4px 4px;
    color: #080707;
  }
  .el-dropdown-menu__item:hover {
    color: #080707;
    background: #eeeeee;
  }
}

// 分页、统计
::v-deep .flexBox {
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // 表格下边框一直固定效果
  border-top: 1px solid #eeeeee;
  .statisticsBox {
    .spanBox {
      padding-right: 6px;
    }
    .iBox {
      font-size: 16px;
    }
    .iiBox {
      font-style: inherit;
      color: #e1e1e1;
    }
    .apinameBox {
      color: #666666;
      padding: 0 4px;
    }
    .svgBox4:hover {
      background-color: #f3f7ff;
    }
    .svgBox4 {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      height: 20px;
      width: 20px;
      text-align: center;
      border-radius: 4px;
      .admin_ss {
        width: 14px;
      }
    }
  }
  .el-pagination__jump {
    margin-left: 0;
  }
}
// 处理表格表头样式
::v-deep .el-table__header {
  tr {
    th:first-child {
      border-right: none !important;
    }
    th:nth-child(2) {
      border-left: none !important;
    }
  }
}
// 下拉菜单样式修改
::v-deep .popper__arrow {
  display: none !important;
}
.dialogModel {
  // 弹窗内联编辑防止头部出现一条线
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  // 改变弹窗层级
  ::v-deep .el-dialog__wrapper {
    z-index: 2001 !important;
  }
}
::v-deep .handleCol {
  .el-dialog__header {
    padding: 10px 16px;
  }
  .el-dialog__body {
    padding: 0;
    padding-bottom: 20px;
  }
  .el-dialog__footer {
    padding: 10px 16px;
  }
}
// 内联编辑
.lineBox {
  position: fixed;
  width: 240px;
  top: 10px;
  left: 20px;
  padding: 8px;
  z-index: 2002;
  background: #ffffff;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.12);
  .editBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 4px 0;
    i {
      font-size: 20px;
      padding-left: 8px;
    }
  }
}

// 列表操作按钮
.tableButton {
  margin-top: 4px;
  cursor: pointer;
  width: 16px;
  height: 14px;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}

// 表头锁定按钮
.lockedImg {
  margin-top: 4px;
  width: 11px;
}

.content-main {
  position: relative;
  clear: both;
  height: calc(100% - 40px);
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 0;
  width: calc(100% - 20px);
}

::v-deep .el-progress-bar__outer {
  margin-right: 10px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 排序图标
.sortImg {
  height: 16px;
  width: 16px;
  display: inline-block;
}

// 编辑图标样式
.editIconImg {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  height: 12px;
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid $--background-color-hover;
}
// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid $--background-color-hover;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid $--background-color-hover;
}

::v-deep .el-table--border th {
  border-right: 1px solid $--background-color-hover;
}
// 新版表格统一表头高度36px
::v-deep .el-table__header tr th {
  padding: 6px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}
// 表格多选按钮与表头一致
::v-deep .el-table__body {
  .el-checkbox {
    margin-right: 0;
  }
}
.lineBoxEdit {
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.admin_s {
  margin: 4px 0 0 0px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table th .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
// 新版表格行统一高度36px
::v-deep .el-table td {
  position: relative;
  padding: 6px 0;
  color: #080707;
  border-bottom: 1px solid $--background-color-hover;
  word-break: break-all;

  .cell {
    min-height: 23px;
    position: relative;

    > span,
    > a {
      width: calc(100% - 1px);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > span > img {
        right: 5px !important;
      }
    }
    .user_box {
      line-height: 23px;
      height: 23px;
      display: flex;
      .headPortrait {
        .logoname {
          font-size: 12px !important;
          line-height: 23px;
        }
      }
      a {
        width: calc(100% - 27px);
        margin-left: 5px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}

::v-deep .el-input__prefix {
  top: 0 !important;
}
</style>
<style lang="scss">
@import "@/style/variables.scss";
// 选中行背景样式
// row-class-name需要设置为全局样式，否则不生效
.el-table .rowBox_chen {
  background-color: $--color-primary-light-06;
}
.table-popover-span {
  .el-checkbox {
    display: block;
  }
}
.pop_small_edit {
  border: 1px solid #e1e1e1 !important;
}
.pop_small_edit ul {
  margin: 0 -12px;
  min-height: 26px;
  padding: 0 4px;
}

.pop_small_edit li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
  border-radius: 4px;
  margin-bottom: 4px;
}
.pop_small_edit li span {
  padding-left: 10px;
}
.pop_small_edit li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.cannotEdit {
  box-sizing: border-box;
  padding-right: 15px;
}
.myDiv {
  width: 100%;
  height: 100%;
}
// 表格滚动错位
.myTable {
  height: 100%;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid #dedcda;
  .el-table--border {
    border: none;
  }
  .el-table {
    overflow: visible;
  }
  .el-table__header-wrapper {
    overflow: hidden;
    position: sticky;
    top: 0px;
    z-index: 10;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
  }
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    height: 0px;
  }
  .el-table__fixed {
    overflow: visible;
  }
  .el-table__fixed-header-wrapper {
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
</style>