<template>
  <div>{{title || "sdfsdfsd"}}</div>
</template>

<script>
/**
 * 表格头部组件
 */
export default {
  props:{
    // 表头展示内容
    title:{
      type:String,
      default:''
    }
  },
    data(){
        return {}
    }

}
</script>

<style>

</style>