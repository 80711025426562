<!-- 调整预测弹框 -->
<template>
  <div>
    <el-dialog
      :title="$t('label_adjust_forecast')"
      width="30%"
      :visible.sync="editForeToProps"
      :before-close="editForeCancel"
      :modal-append-to-body="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('label_submit_forecast')" prop="forenum">
          <el-input v-model="ruleForm.forenum"></el-input>
        </el-form-item>
        <el-form-item :label="$t('label_not_adjust')+':'">
          <label>{{forenum === "0"?'￥'+ Number(nowForem).toFixed(2):Number(nowForem).toFixed(2)}}</label>
        </el-form-item>
        <el-form-item :label="$t('label_adjust_remark')">
          <el-input type="textarea" :rows="2" v-model="ruleForm.desc" maxlength="30">
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <p @click="clearance('ruleForm')">{{$t("label_clear_adjust")}}</p>
        <div class="btn">
          <el-button @click="editForeCancel">{{
            $t("button_partner_cancel")
          }}</el-button>
          <el-button type="primary" @click="editForeConfirm('ruleForm')">{{
            $t("label.save")
          }}</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    nowForeDescription:{
      type:String,
      default:""
    },
    nowForem: {
      type: String,
      default: "",
    },
    editForeTo: {
      // 弹框显示隐藏
      type: Boolean,
      default: false,
    },
    // 预测类型。"0":收入,"1"：数量
    forenum:{
      type:String,
      default:'0'
    }
  },
  watch:{
      editForeTo(newvalue) {
        this.editForeToProps=newvalue
      if (newvalue) {
        this.$nextTick(() => {
          this.$refs["ruleForm"].resetFields();
          this.clearTable();
        });
      }
    },
  },
  data() {
    return {
      editForeToProps:false,
      ruleForm: {
        forenum:"",
        desc:"",
      },

       rules: {
       forenum: [
          {
            required: true,
            trigger: "blur",
            message:"请输入"
          },
        ],
      },
      text: "",
      textarea: "",
      tiaoz:"",
    };
  },
 created(){

 },
  methods: {
    clearTable(){
     this.ruleForm.forenum=""
     this.ruleForm.desc=""
    },
    //关闭弹窗
    editForeCancel() {
      this.$emit("editForeCancel");
      this.ruleForm.forenum=""
    },
       editForeConfirm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
           this.$emit("editForeConfirm",this.ruleForm);
          } else {
            return false;
          }
        });
      },
    clearance(){
      this.$emit("clearance",this.ruleForm);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  border-top: none;
}
.dialog-footer {
  p {
    font-size: 12px;
    color: #006dcc;
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
}
::v-deep .el-input__inner {
  // width: 315px;
  height: 30px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}
::v-deep .el-dialog__body {
  padding: 16px 8px;
  padding-right: 30px;
}
::v-deep .el-textarea__inner {
  // width: 315px;
  height: 87px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}
::v-deep .el-form-item:nth-child(1) {
  margin-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 14px;
  color: #080707;
}
::v-deep label {
  font-size: 12px;
  color: #888888;
}
::v-deep .el-form-item:nth-child(2) {
  .el-form-item__label {
    font-size: 12px;
    color: #888888;
  }
}
</style>