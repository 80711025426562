<!-- 预测页 -->
<template>
  <div class="forecast_box">
    <div class="drop" v-if="isQuery">
      <div class="noPermissionBox">
        <svg aria-hidden="true">
          <use xlink:href="#icon-myProject_nodata"></use>
        </svg>
        <p class="noPermissionTip">
          <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
          {{ $t("vue_label_notice_nopermission_view") }}
        </p>
      </div>
    </div>
    <div class="forecastList" v-else>
      <div class="forecast_content">
        <!-- 头部信息 -->
        <div class="forecast_top">
          <div class="top_message">
            <!-- 用户信息 -->
            <div class="top_tltle">
              <div class="user_avatar">
                <img :src="getPersonChargeImg()" alt="" />
              </div>
              <div class="user_zi">
                <div class="forecast_type">
                  {{ $t("UG_Forcast_01") }}&nbsp;>&nbsp;{{ totalType
                  }}{{ yhuoj }}
                </div>
                <div class="forecast_yh">
                  <div class="username">
                    <div @click="clickSearch" class="nn">
                      <span class="usernnn">{{ foreuser }}</span>
                      <span>
                        <svg aria-hidden="true" class="usernn">
                          <use xlink:href="#icon-more"></use>
                        </svg>
                      </span>
                      <!-- 搜索弹窗 -->
                      <div class="search_ball" v-show="searchTo" @click.stop>
                        <span>{{
                          $t("label_search_otherusers_forecast")
                        }}</span>
                        <span>
                          <el-select
                            :placeholder="$t('label.searchs')"
                            class="no-suffix"
                            filterable
                            clearable
                            remote
                            v-model="svalue"
                            :loading="loadingg"
                            :remote-method="remoteMethod"
                            @change="changeEvent"
                          >
                            <!-- @keyup.enter.native="changeEvent" -->
                            <p class="searchTipBox">
                              <span
                                @click="remoteSearch()"
                                style="display: inline-block; width: 100%"
                              >
                                <i class="el-icon-search"></i>
                                <span style="padding-left: 10px">
                                  <!-- 前往精准搜索 -->
                                  {{
                                    $t(
                                      "vue_label_commonobjects_detail_to_precise_search"
                                    )
                                  }}
                                </span>
                              </span>
                            </p>
                            <el-option
                              v-show="optionListfa"
                              v-for="item in optionList"
                              :key="item.id"
                              :label="item.label"
                              :value="item.id"
                            >
                              <!-- @click.native="qeqe(item)" -->
                            </el-option>
                          </el-select>
                        </span>
                        <div class="search_list" v-show="searchToo">
                          <ul>
                            <li
                              v-for="(item, index) in newa"
                              :key="index"
                              @click="changeUser(item)"
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                        <span>{{ $t("label_my_forecast") }}</span>
                        <p @click="gomee">{{ myName }}</p>
                        <!-- <span>共享给我的预测</span> -->
                        <!-- <p>是是是</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="right_infos">
              <div class="infos_box">
                <div class="refresh" @click="refresh">
                  <i class="el-icon-refresh-right"></i>
                </div>
                <div class="set" @click="clickSet">
                  <i class="el-icon-setting"></i>
                  <div class="nnn">
                    <!-- 设置弹窗 -->
                    <div class="set_bounce" v-show="setTo">
                      <ul class="parent">
                        <li @click.stop>{{ $t("UG.Forcast.01") }}</li>
                        <!-- 设置预测范围 -->
                        <li @click="scopeFore()">
                          {{ $t("label_set_ forecast_scope") }}
                        </li>
                        <li @click.stop>{{ $t("label_display_options") }}</li>
                        <ul class="elements">
                          <li
                            v-for="(item, index) in forecastOptions"
                            :key="index"
                            @click="getForecastOptions(item)"
                          >
                            <i :class="item.icon"></i>
                            {{ $t(item.label) }}
                          </li>
                        </ul>
                        <li @click.stop>
                          {{ $t("label.forecast.settings.type") }}
                        </li>
                        <ul class="elements">
                          <li
                            v-for="(item, index) in forecastTypes"
                            :key="index"
                            @click="getForecastTypes(item)"
                          >
                            <i :class="item.icon"></i>
                            {{ item.label }}
                          </li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
                 <div class="refresh" @click="downLoad">
                  <i class="el-icon-document" v-if="!downloadLoading"></i>
                  <i class="el-icon-loading" v-if="downloadLoading"></i>
                </div>
              </div>
              <div class="timer">
                <span>
                  {{
                    $t("vue_label_commonobject_view_updatedago", {
                      timeInterval,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- 关系跳转 -->
          <div class="forecast_related" v-if="tiaoz">
            <p @click="gome">{{ realName }}</p>
            <span>></span>
            <p>{{ foreuser }}</p>
          </div>
        </div>
        <!-- 头部表单列表 -->
        <forecastTopTable
          ref="forecastTop"
          :ismanager="ismanager"
          :forcastType="forcastType"
          :sumNumber="sumNumber"
          :topTablesum="topTablesum"
          :foreuser="foreuser"
          :tableArr="tableArr"
          :cpxllist="cpxllist"
          :loading="loading"
          :income="income"
          :userid="appraduserlookup___0_lkid"
          :forenum="forenum"
          :foreperiod="foreperiod"
          :foredis="foredis"
          :totalType="totalTypee"
          :director="director"
          :foretype="foretype"
          :amount="amount"
          :quota="quota"
          :percents="percents"
          :optionList="optionList"
          :setfore="setfore"
          :totalTypee="totalTypee"
          :currency="currency"
          :tableArrChildren="tableArrChildren"
          @rowClick="rowClick"
          @goDeta="goDeta"
        />
        <!-- 底部表单列表 -->
        <forecastBottomTable
          ref="forecastBottom"
          :loadings="loadings"
          :foreuser="foreuser"
          :headerType="headerType"
          :tableHeader="tableHeader"
          :businessList="businessList"
          :foreperiod="foreperiod"
          :foreyear="foreyear"
          :tableDet="tableDet"
          :tableDetail="tableDetail"
          :appraduserlookup___0_lkid="appraduserlookup___0_lkid"
          @loadScroll="loadScroll"
        />
        <!-- 设置预测范围 -->
        <scopeFore
          ref="scopeFore"
          :foreyear="foreyear"
          :foremon="foremon"
          :foreper="foreper"
          :qdyearlist="qdyearlist"
          :scopeForeTo="scopeForeTo"
          :setfore="setfore"
          @scopeForeConfirm="scopeForeConfirm($event)"
          @scopeForeCancel="scopeForeCancel"
        />
        <serarchFore
          :serarchForeTo="serarchForeTo"
          :optionListt="optionListt"
          @sx="sx"
          @serarchForeCancel="serarchForeCancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 预测首页
 */
import forecastBottomTable from "./components/forecastBottomTable";
import {
  getViewForecast,
  imgGetUserInfo,
  getForecastQueryTabList,
  getForecastSaveTotal,
  getForecastViewDetail,
  getForecastSubmit,
  getviewlist,
  getForecastEditOpp,
  userMessage,
  getForecastSet,
  getLookupResults,
  getViewListHeader,
  viewForecastAllCpxl,
  getUserInfo,
  downloadManual,
  getSelectValue
} from "./api.js";
import serarchFore from "./components/forecastDialog/serarchFore";
import forecastTopTable from "./components/forecastTopTable";
import scopeFore from "./components/forecastDialog/scopeFore";
import VueCookies from "vue-cookies";
export default {
  components: {
    scopeFore,
    forecastBottomTable,
    forecastTopTable,
    serarchFore,
  },
  data() {
    return {
      // 判断是否是经理
      ismanager:'',
      // 预测日期
      foredate:'',
      // 数据总条数
      totalCount: 1,
      // 当前页数
      page:1,
      // 每页展示的数据条数
      pageSize:50,
      // 获取底部表格数据的参数，方便滚动获取更多数据时使用
      formData:{},
      sumNumber: "",
      fullscreenLoading: true,
      setTo: false, //设置列表
      loading: false,
      loadings: false,
      searchTo: false, //搜索列表
      timeInterval: "",
      userId: "",
      optionListt: null,
      svalue: "", //搜索
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      inputWidth: "399px", //搜索框宽度
      oldImgSrc: "", //用户头像
      realName: "", //用户名
      foreuser: "",
      scopeForeTo: false, //设置范围
      editForeTo: false, //调整预测
      serarchForeTo: false,
      pitch: "el-icon-check", //选中图标
      isQuery: false,
      isQuerys: false,
      searchToo: false,
      setfore: null,
      optionListfa: false,
      appraduserlookup___0_lkid: "",
      forecastOptions: [
        {
          value: "1",
          label: "label_display_quota",
          icon: "el-icon-check",
        },
        {
          value: "2",
          label: "label_quota_percentage",
          icon: "el-icon-check",
        },
        // {
        //   value: "3",
        //   label: "显示全部为零值的行",
        //   icon: "el-icon-check",
        // },
      ],
      forecastTypes: null, //预测类型
      forenum: "", //预测数字
      foreyear: "", //预测年度
      foremon: "", //预测范围启点
      foreper: "", //范围长度
      foredis: "0", //显示单位
      selectab: "",
      foreperiod: "",
      totalType: "", //预测类型
      totalTypee: "",
      director: "", //预测人
      foretype: "",
      cpxl: "all", //产品预测
      qdyearlist: [], //预测年份设置列表
      tableArr: [], //表格数据
      tableArrChildren: [],
      threeChildren: [],
      searchKeyWord: "",
      searchtype: "User",
      newa: [],
      income: false,
      forcastType: "",
      amount: false,
      cpxllist: [], //预测列表
      cpxlId: "",
      lksrch: "", //搜索内容
      lktp: "005", //对象的前缀
      lknm: "appraduserlookup___0", //查找字段的名称
      lkdp: "forecastuser",
      objId: "opportunity_forecast",
      viewId: "aec202141FF9564xaeTj",
      loadingg: false,
      startDate: "", //开始时间
      endDate: "", //结束时间
      tableHeader: [], //底部表格表头
      businessList: [], //底部表格列表
      pageNum: 1, //页码默认为1
      noDataTip: false,
      quota: true, //定额
      percents: true, //定额完成百分比
      iszearo: true, //全部为零
      currency: "",
      tableDet: false,
      zhongs: [
        {
          dearr: [], //收入定额总数集合
          cnearr: [], //收入承诺额总数集合
          yqdarr: [], //收入已签单总数集合
          ccfharr: [], //收入超长发挥总数集合
          clzarr: [], //收入处理中总数集合
          Sdearr: [], //收入定额总数集合
          Scnearr: [], //数量承诺额总数集合
          Syqdarr: [], //数量已签单总数集合
          Sccfharr: [], //数量超长发挥总数集合
          Sclzarr: [], //数量处理中总数集合
        },
      ],
      tableDetail: null, //表格详情
      headerType: "",
      topTablesum: [
        {
          desum: "", //定额总数
          yqdsum: "", //已签单总数
          ccfhsum: "", //超长发挥总数
          clzsum: "", //处理中总数
          cnesum: "", //承诺额总数
          deqntsum: "", //定额总数
          yqdqntsum: "", //已签单总数
          ccfhqntsum: "", //超长发挥总数
          clzqntsum: "", //处理中总数
          cneqntsum: "", //承诺额总数
        },
      ],
      serarchList: [],
      optionList: [{}],
      tiaoz: false,
      cpxlname: "",
      // 头像
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      dearr: [], //收入定额周期集合
      cnearr: [], //收入承诺额周期集合
      yqdarr: [], //收入已签单周期集合
      ccfharr: [], //收入超长发挥周期集合
      clzarr: [], //收入处理中周期集合
      Sdearr: [], //收入定额周期集合
      Scnearr: [], //数量承诺额周期集合
      Syqdarr: [], //数量已签单周期集合
      Sccfharr: [], //数量超长发挥周期集合
      Sclzarr: [], //数量处理中周期集合
      ssdearr: [],
      sscnearr: [],
      ssyqdarr: [],
      ssccfharr: [],
      ssclzarr: [],
      ssSdearr: [],
      ssScnearr: [],
      ssSyqdarr: [],
      ssSccfharr: [],
      ssSclzarr: [],
      yhuoj: "",
      forecastdatasAll: null,
      myName:"",
      myId:"",
      downloadLoading:false,//判断当前是否正在下载
    };
  },
  watch: {
    tiaoz() {
      if (this.tiaoz === false) {
        document.querySelector(".forecast_top").style.height = "69px";
      } else {
        document.querySelector(".forecast_top").style.height = "96px";
      }
    },
    getViewForecast(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.tableArr = [];
          this.tableArrChildren = [];
        });
      }
    },
    tableDetail() {
      if (this.tableDetail !== null) {
        this.noDataTip = true;
      } else {
        this.noDataTip = false;
      }
    },
    searchTo(newvalue) {
      if (newvalue) {
        this.$nextTick(() => {
          this.svalue = "";
        });
      }
    },
  },
  async created() {
    // 设置document.title
    window.document.title=this.$i18n.t('label.forecast.customforecast.title')+' | CloudCC'
    await this.getUserName();
    await this.getForecastSet();
    await this.getViewForecast();
    await this.getViewListHeader();
    await this.getForecastQueryTabList();
  },
  mounted() {
    this.closeAll();
    this.seraaKda();
    this.alloptionList();
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;
      // 计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      // 计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      // 计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      // 计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  methods: {
    /*
     * 下载目标预测帮助手册
     */
     downLoad(){
      this.downloadLoading = true
       downloadManual().then((res) => {
        var link = document.createElement("a");
        link.setAttribute("download","目标预测帮助手册");
        link.href = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
        link.click();
        this.downloadLoading = false
      });
    },
    closeAll() {
      let body = document.querySelector("body");
      let usernnn = document.querySelector(".usernnn");
      let usernn = document.querySelector(".usernn");
      let nnn = document.querySelector(".nnn");
      body.addEventListener("click", (e) => {
        if (e.target != nnn && e.target != usernnn && e.target != usernn){
          this.setTo = false;
          this.searchTo = false;
        }
      });
    },
    // 获取到负责人头像
    getPersonChargeImg() {
      let ids = this.appraduserlookup___0_lkid;
      return `${this.imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${ids}&binding=${this.tokenCookie}`;
    },
    // yichu() {
    //   setTimeout(() => {
    //     this.searchTo = false;
    //   }, 1000);
    // },
    async imgGetUserInfo() {
       await imgGetUserInfo({});
    },

    //获取表格头部
    async getViewListHeader() {
      let res = await getViewListHeader({
        obj: this.lktp,
        viewId: this.viewId,
      });
     this.tableHeader = res.data.headLabel;
     

    },
    async getviewlist() {
      await getviewlist({
        objId: this.objId,
      });
    },
    //预测设置
    async getForecastSet() {
      let res = await getForecastSet({});
      this.foredate= res.data.list.forecastDate; // 预测时间
      this.foreper = res.data.list.defaultRangeDuration; //默认范围长度
      this.forenum = res.data.list.defaultForecastNumbers; //默认预测数字
      this.forcastType = res.data.list.forecastType;
      var dada = res.data.list.defaultRangeStart; //默认范围启点
      res.data.clist.forEach((cpx) => {
        this.cpxlId += "," + cpx.codevalue;
      });
      this.cpxlId = this.cpxlId.substr(1);
      //为0按月计算 为1按季度计算
      if (res.data.list.forecastPeriod == 0) {
        //根据当前时间设置默认起始日期
        let dad
        if (dada.slice(1) == "") {
          dad = dada;
        } else {
          dad = dada.slice(1);
        }
        let sdtime4
        if (dada == "-" + dad) {
          sdtime4 = new Date().setMonth(new Date().getMonth() - dad);
        }
        if (dada == dad) {
          sdtime4 = new Date().setMonth(
            new Date().getMonth() + Number(dad)
          );
        }
        if (dada == 0) {
          sdtime4 = new Date().setMonth(new Date().getMonth());
        }
        var date = new Date(sdtime4);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        this.foreyear = year.toString();
        this.foremon = month.toString();
      } else if (res.data.list.forecastPeriod == 1) {
        let dad
        if (dada.slice(1) == "") {
          dad = dada;
        } else {
          dad = dada.slice(1);
        }
        let sdtime4
        if (dada == "-" + dad) {
          sdtime4 = new Date().setMonth(new Date().getMonth() - dad * 3);
        }
        if (dada == dad) {
          sdtime4 = new Date().setMonth(
            new Date().getMonth() + Number(dad * 3)
          );
        }
        if (dada == 0) {
          sdtime4 = new Date().setMonth(new Date().getMonth());
        }
        var newdate = new Date(sdtime4);
        let year = newdate.getFullYear();
        let month = newdate.getMonth() - 1;
        if (month < 3) {
          month = 1;
        } else if (month < 6) {
          month = 2;
        } else if (month < 9) {
          month = 3;
        } else if (month < 12) {
          month = 4;
        }
        this.foreyear = year.toString();
        this.foremon = month.toString();
      }
      //默认设置预测类型
      if (res.data.list.forecastType == 0) {
        this.forecastTypes = [
          {
            value: "1", //业务机会收入
            label: this.$i18n.t("label_business_income"),
            icon: "el-icon-check",
          },
          {
            value: "2", //业务机会数量
            label: this.$i18n.t("label_business_count"),
            icon: "",
          },
        ]; //业务机会
        this.totalType = this.$i18n.t("vue_label_normal_opportunity_sf");
        this.foretype = "0";
      } else {
        this.forecastTypes = [
          {
            value: "1",
            label: this.$i18n.t("label_productline_income"),
            icon: "el-icon-check",
          },
          {
            value: "2",
            label: this.$i18n.t("label_productline_count"),
            icon: "",
          },
        ]; //产品系列
        this.totalType = this.$i18n.t("label.forecast.customforecast.cpxl");
        this.foretype = "1";
      }
      //0收入否则数量
      if (res.data.list.forecastPeriod == 0) {
        this.setfore = true;
        this.foreperiod = "0";
        this.yhuoj = this.$i18n.t("label.forecast.settings.sr"); //收入
        this.forecastTypes[0].icon = this.pitch;
        this.forecastTypes[1].icon = "";
      } else {
        this.setfore = false;
        this.foreperiod = "1";
        this.yhuoj = this.$i18n.t("label.file.num"); //数量
        this.forecastTypes[0].icon = "";
        this.forecastTypes[1].icon = this.pitch;
      }
      //当默认为收入数量时候默认显示为收入
      if (this.forenum == 2) {
        this.income = true;
        this.forenum = "0";
        this.yhuoj = this.$i18n.t("label.forecast.settings.sr"); //收入
        this.forecastTypes[0].icon = this.pitch;
        this.forecastTypes[1].icon = "";
      }
      if (this.forenum == 0) {
        this.income = true;
        this.yhuoj = this.$i18n.t("label.forecast.settings.sr"); //收入
        this.forecastTypes[0].icon = this.pitch;
        this.forecastTypes[1].icon = "";
      }
      if (this.forenum == 1) {
        this.amount = true;
        this.yhuoj = this.$i18n.t("label.file.num"); //数量
        this.forecastTypes[0].icon = "";
        this.forecastTypes[1].icon = this.pitch;
      }
    },
    remoteSearch() {
      this.serarchFore();
    },
    //根据子节点进行跳转
    goDeta(goUser) {
      //判断是否是自己的预测
      if (
        goUser.nameId == "" ||
        goUser.nameId == null ||
        goUser.nameId == undefined
      ) {
        //为空的话不做操作
        // this.appraduserlookup___0_lkid = this.appraduserlookup___0_lkid;
        // this.foreuser =this.realName;
      } else {
        this.appraduserlookup___0_lkid = goUser.nameId;
        this.foreuser = goUser.Username;
        this.tableArr = [];
        this.tableArrChildren = [];
        //控制导航条是否出现
        if (this.realName !== this.foreuser) {
          this.tiaoz = true;
        } else {
          this.tiaoz = false;
        }
        this.refresh();
      }
    },
    /**
     * 表格滚动加载更多
     */
    loadScroll(){
      let num = this.totalCount/this.pageSize;
      if(num > this.page) {
        this.page++
        this.rowClick('more')
      }
    },
    /**
     * 内联编辑下拉阶段字段时，获取选项列表的值
     * @param {String} fieldId:字段id
     * @param {Object} fieldObj:当前字段对象
     */
    getSelectValue(fieldId='',fieldObj={}){
      getSelectValue({fieldId}).then(res=>{
        if(res&&Array.isArray(res.data)){
          fieldObj.options=res.data
        }
      })
    },
    // 获取当前年月份天数
    mGetDate(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    //筛选底部表格
    rowClick(formm={}) {
      if(formm==='more'){
        // 底部表格滚动获取更多数据
        formm=this.formData
      }else{
        // 点击头部表格首次获取底部表格数据
        this.page=1
        this.formData=formm
      }
      this.loadings = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        userid: this.appraduserlookup___0_lkid,
        forenum: formm.forenum,
        foreyear: this.foreyear,
        foremon: formm.foremonn,
        foreperiod: this.foreperiod,
        foredis: this.foredis,
        cpxl: (formm.cpxl===this.$i18n.t("label.tabpage.nothingz")||!formm.cpxl)?'':formm.cpxl,
        totalType: formm.totalTypeee,
        foretype: this.foretype,
        currency: formm.currencyy,
        director: formm.directorr,
        foredate: this.foredate,
        startDate:this.foreyear+`-${Number(formm.foremonn)>9?formm.foremonn:'0'+formm.foremonn}-01`,
        endDate:this.foreyear+`-${Number(formm.foremonn)>9?formm.foremonn:'0'+formm.foremonn}-${this.mGetDate(this.foreyear,formm.foremonn)}`
      };
    /**
     * 获取底部表格数据
     */
      getForecastViewDetail(params).then((res) => {
        if(this.$store.state.userInfoObj.language != "zh") {
          res.data.detaillist.map((item)=> {
            item.forecasttype = item.forecasttypeccname;
          })
        }
        this.tableDetail = res.data.detaillist;
        this.totalCount = res.data.totalCount; // 数据总量
        let arr = res.data.tableLabels || [];
        arr.forEach((item) => {
          item.schemefieldName = item.apiname;
          item.nameLabel = item.label;
          item.schemefieldType = item.fieldtype
          if(item.apiname==='jieduan'){
          // 获取阶段字段的选项列表
          this.getSelectValue(item.id,item)
        }
        });
        this.tableHeader = arr;
        //去掉最后一项总数
        // this.tableDetail.pop();
        setTimeout(() => {
          this.loadings = false;
        }, 500);
        this.tableDet = true;
      });
    },

    async getForecastEditOpp() {
      await getForecastEditOpp();
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 0) {
          //等于0证明已经到底，可以请求接口
          that.fnThrottle(that.append, 500, 1000)();
        }
      });
    },
    append() {
      if (!this.noDataTip) {
        this.getForecastQueryTabList();
      }
      // 
    },

    async getForecastSaveTotal() {
       await getForecastSaveTotal({});
    },
    // 业务机会预测选项卡查询
    async getForecastQueryTabList() {
      this.loadings = true;
      this.pageNum = 1;
      let params = {
        viewId: this.viewId,
        page: this.pageNum,
        pageSize: this.pageSize,
        startDate: this.startDate,
        endDate: this.endDate,
        foretype: this.foretype,
        userid: this.appraduserlookup___0_lkid,
      };
      let res = await getForecastQueryTabList(params);
      setTimeout(() => {
        this.loadings = false;
      }, 500);
      //底部表格分页
      if (res.result && res.returnCode === "1") {
        if (this.pageNum === 1) {
          this.businessList = [];
        }
        if (res.data?.list?.list?.length === 0 && this.pageNum === 1) {
          this.businessList = [];
        } else if (this.businessList.length === 0 || this.pageNum === 1) {
          this.businessList = res.data.detaillist;
          this.pageNum++;
        } else {
          this.businessList = [...this.businessList, ...res.data.detaillist];
          this.pageNum++;
        }
        if (this.noDataTip == false) {
          this.loadMore();
        }
        if (res.data && res.data.detaillist && res.data.detaillist.length == 0) {
          this.noDataTip = true;
        }
        if(this.businessList && this.businessList != undefined) {
          this.totalSize = this.businessList.length;
        }
        this.loadings = false;
      }
    },
    // 业务机会预测查询
    // 列表数据
    async getViewForecast() {
      //根据后台设置调用不同预测类型的接口 0为整体预测 1为产品系列预测
      if (this.forcastType == 0) {
        // 整体预测不需要传cpxl
        this.loading = true;
        let params = {
          appraduserlookup___0_lkid:this.appraduserlookup___0_lkid,
          forenum: this.forenum,
          foreyear: this.foreyear,
          foremon: this.foremon,
          foretype: "0",
          foreper: this.foreper,
          foredis: this.foredis,
          selectab: this.selectab,
          // cpxl: this.cpxlId,
          foreperiod: this.foreperiod,
        };
        try {
          var res = await getViewForecast(params);
          
        } catch (error) {
          this.isQuery = true;
        }
        //缺省页
        if (res && res.data && (res.data === "" || res.data === null || res.data === undefined)) {
          this.isQuery = true;
        } else {
          this.isQuery = false;
        }
        this.qdyearlist = res.data.qdyearlist;
        setTimeout(() => {
          this.loading = false;
          this.fullscreenLoading = false;
        }, 500);
        // 判断是否是经理
        this.ismanager=res.data.ismanager
        //如果下级有产品系列的话调用rootmap否则调用forecastdatas
        if (res.data.rootmap.length > 0) {
          this.forecastdatasAll = res.data.forecastdatas;
          res.data &&
            res.data.rootmap.forEach((item,index) => {
              // 保留原本父级定额值(业务机会收入)
              // let oldQuotas = res.data.rootmap[index].quotas;
              // 保留原本父级定额值(业务机会数量)
              // let oldQuotaqnt = res.data.rootmap[index].quotaqnt;
              this.cnearr = [];
              this.dearr = [];
              this.ssdearr = [];
              this.cnearr = [];
              this.sscnearr = [];
              this.yqdarr = [];
              this.ssyqdarr = [];
              this.ccfharr = [];
              this.ssccfharr = [];
              this.clzarr = [];
              this.ssclzarr = [];
              this.Sdearr = [];
              this.Scnearr = [];
              this.Syqdarr = [];
              this.Sccfharr = [];
              this.Sclzarr = [];
              this.ssSdearr = [];
              this.ssScnearr = [];
              this.ssSyqdarr = [];
              this.ssSccfharr = [];
              this.ssSclzarr = [];
              //产品预测不可编辑总额
              this.$set(item, "isEddit", false);
              this.$set(item, "isFilter", false);
              this.tableArr.push(item);
              if (this.foreperiod === "0") {
                this.$set(
                  item,
                  "date",
                  this.$i18n.t('c962',{year:item.forecastyear,month:item.forecastperiod})
                );
              } else {
                this.$set(
                  item,
                  "date",
                  this.$i18n.t('c961',{year:item.forecastyear,quarter:item.forecastperiod})
                );
              }
              this.$set(item, "zhuantu", false);
              
              item.children.map((ite,idx)=>{
                if(ite.director == res.data.userid) {
                  res.data.rootmap[index].quotas = item.children[idx].quotas
                  res.data.rootmap[index].quotaqnt = item.children[idx].quotaqnt
                  // 业务机会收入
                  item.children[idx].quotas = item.currency + " 0.00" // 定额
                  // 业务机会数量
                  item.children[idx].quotaqnt = "0.00" // 定额
                }
              })
              //显示文字
              item.children.forEach((ite)=>{
                this.$set(ite, "isFilter", true);
                  this.$set(ite, "isEddit", true);
                  this.$set(ite, "zhuantu", true);
                  this.$set(ite, "series", ite.series);
                  //添加用户名
                  this.$set(ite, "date", ite.username);
              })
                item.children[item.children.length - 1].zhuantu = false;
                item.children[item.children.length - 1].isEddit = false;
                //加头像
                item.children.forEach((ite) => {
                  if (ite.isrepcommits === "self") {
                    ite.isrepcommits = false;
                  }
                  this.$set(ite, "isImages", true);
                });
              this.zhongs.forEach((sum) => {
                //整合周期总数
                if (this.forenum == "0") {
                  //整合周期总数
                  let de = item.quotas.split(" ");
                  let yqd = item.closeds.split(" ");
                  let cne = item.commits.split(" ");
                  let ccfh = item.bests.split(" ");
                  let clz = item.pipelines.split(" ");
                  //定额去掉千分符相加
                  let _de = de[1];
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  sum.dearr.push(_de);
                  let desum = 0;
                  sum.dearr.forEach((item) => {
                    desum += +item;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd[1];
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  sum.yqdarr.push(_yqd);
                  let yqdsum = 0;
                  sum.yqdarr.forEach((item) => {
                    yqdsum += +item;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne[1];
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  sum.cnearr.push(_cne);
                  let cnesum = 0;
                  sum.cnearr.forEach((item) => {
                    cnesum += +item;
                  });
                  _cne = cnesum.toString();
                  // 
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh[1];
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  sum.ccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  sum.ccfharr.forEach((item) => {
                    ccfhsum += +item;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz[1];
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  sum.clzarr.push(_clz);
                  let clzsum = 0;
                  sum.clzarr.forEach((item) => {
                    clzsum += +item;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.cnesum = cne[0] + " " + zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.clzsum = clz[0] + " " + zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.desum = de[0] + " " + zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.yqdsum = yqd[0] + " " + zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.ccfhsum = ccfh[0] + " " + zgds;
                  }
                } else {
                  //整合周期总数
                  let de = item.quotaqnt.split(" ");
                  let yqd = item.closedsqnt.split(" ");
                  let cne = item.commitsqnt.split(" ");
                  let ccfh = item.bestsqnt.split(" ");
                  let clz = item.pipelinesqnt.split(" ");
                  //定额去掉千分符相加
                  let _de = de;
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  sum.Sdearr.push(_de);
                  let desum = 0;
                  sum.Sdearr.forEach((item) => {
                    desum += +item;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd;
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  sum.Syqdarr.push(_yqd);
                  let yqdsum = 0;
                  sum.Syqdarr.forEach((item) => {
                    yqdsum += +item;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne;
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  sum.Scnearr.push(_cne);
                  let cnesum = 0;
                  sum.Scnearr.forEach((item) => {
                    cnesum += +item;
                  });
                  _cne = cnesum.toString();
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh;
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  sum.Sccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  sum.Sccfharr.forEach((item) => {
                    ccfhsum += +item;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz;
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  sum.Sclzarr.push(_clz);
                  let clzsum = 0;
                  sum.Sclzarr.forEach((item) => {
                    clzsum += +item;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.cneqntsum = zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.clzqntsum = zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.deqntsum = zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.yqdqntsum = zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.ccfhqntsum = zgds;
                  }
                }
              });
              this.sumNumber = this.tableArr.length;
            });
        }
        else{
         this.tableArr = res.data.forecastdatas;
        //添加需要展示的周期格式
        this.tableArr.forEach((item) => {
          //整体预测可编辑总额
          this.$set(item, "isEddit", true);
          if (this.foreperiod === "0") {
            this.$set(
              item,
              "date",
              `${item.forecastyear}${this.$i18n.t("year")}${
                item.forecastperiod
              }${this.$i18n.t("month")}`
            );
          } else {
            this.$set(
              item,
              "date",
              `${item.forecastyear}${this.$i18n.t("year")}${this.$i18n.t(
                "label.sales.dashboard.number"
              )}${item.forecastperiod}${this.$i18n.t("label.account.quarter")}`
            );
          }
          //整合周期总数
          if (this.forenum == "0") {
            //整合周期总数
            let de = item.quotas.split(" ");
            let yqd = item.closeds.split(" ");
            let cne = item.commits.split(" ");
            let ccfh = item.bests.split(" ");
            let clz = item.pipelines.split(" ");
            //定额去掉千分符相加
            let _de = de[1];
            _de = _de.toString();
            _de = _de.replace(/,/gi, "");
            this.dearr.push(_de);
            let desum = 0;
            this.dearr.forEach((item) => {
              desum += +item;
            });
            _de = desum.toString();
            //已签单去掉千分符相加
            let _yqd = yqd[1];
            _yqd = _yqd.toString();
            _yqd = _yqd.replace(/,/gi, "");
            this.yqdarr.push(_yqd);
            let yqdsum = 0;
            this.yqdarr.forEach((item) => {
              yqdsum += +item;
            });
            _yqd = yqdsum.toString();
            //承诺额去掉千分符相加
            let _cne = cne[1];
            _cne = _cne.toString();
            _cne = _cne.replace(/,/gi, "");
            this.cnearr.push(_cne);
            let cnesum = 0;
            this.cnearr.forEach((item) => {
              cnesum += +item;
            });
            _cne = cnesum.toString();
            // 
            //超长发挥去掉千分符相加
            let _ccfh = ccfh[1];
            _ccfh = _ccfh.toString();
            _ccfh = _ccfh.replace(/,/gi, "");
            this.ccfharr.push(_ccfh);
            let ccfhsum = 0;
            this.ccfharr.forEach((item) => {
              ccfhsum += +item;
            });
            _ccfh = ccfhsum.toString();
            //处理中去掉千分符相加
            let _clz = clz[1];
            _clz = _clz.toString();
            _clz = _clz.replace(/,/gi, "");
            this.clzarr.push(_clz);
            let clzsum = 0;
            this.clzarr.forEach((item) => {
              clzsum += +item;
            });
            _clz = clzsum.toString();
            // 定额加千分符
            let decimal = 2;
            let money = "";
            decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
            if (_cne) {
              money = _cne;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.cnesum = cne[0] + " " + zgds;
            }
            if (_clz) {
              money = _clz;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.clzsum = clz[0] + " " + zgds;
            }
            if (_de) {
              money = _de;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.desum = de[0] + " " + zgds;
            }
            if (_yqd) {
              money = _yqd;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.yqdsum = yqd[0] + " " + zgds;
            }
            if (_ccfh) {
              money = _ccfh;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.ccfhsum = ccfh[0] + " " + zgds;
            }
          } else {
            //整合周期总数
            let de = item.quotaqnt.split(" ");
            let yqd = item.closedsqnt.split(" ");
            let cne = item.commitsqnt.split(" ");
            let ccfh = item.bestsqnt.split(" ");
            let clz = item.pipelinesqnt.split(" ");
            //定额去掉千分符相加
            let _de = de[1];
            _de = _de.toString();
            _de = _de.replace(/,/gi, "");
            this.Sdearr.push(_de);
            let desum = 0;
            this.Sdearr.forEach((item) => {
              desum += +item;
            });
            _de = desum.toString();
            //已签单去掉千分符相加
            let _yqd = yqd[1];
            _yqd = _yqd.toString();
            _yqd = _yqd.replace(/,/gi, "");
            this.Syqdarr.push(_yqd);
            let yqdsum = 0;
            this.Syqdarr.forEach((item) => {
              yqdsum += +item;
            });
            _yqd = yqdsum.toString();
            //承诺额去掉千分符相加
            let _cne = cne[1];
            _cne = _cne.toString();
            _cne = _cne.replace(/,/gi, "");
            this.Scnearr.push(_cne);
            let cnesum = 0;
            this.Scnearr.forEach((item) => {
              cnesum += +item;
            });
            _cne = cnesum.toString();
            //超长发挥去掉千分符相加
            let _ccfh = ccfh[1];
            _ccfh = _ccfh.toString();
            _ccfh = _ccfh.replace(/,/gi, "");
            this.Sccfharr.push(_ccfh);
            let ccfhsum = 0;
            this.Sccfharr.forEach((item) => {
              ccfhsum += +item;
            });
            _ccfh = ccfhsum.toString();
            //处理中去掉千分符相加
            let _clz = clz[1];
            _clz = _clz.toString();
            _clz = _clz.replace(/,/gi, "");
            this.Sclzarr.push(_clz);
            let clzsum = 0;
            this.Sclzarr.forEach((item) => {
              clzsum += +item;
            });
            _clz = clzsum.toString();
            // 定额加千分符
            let decimal = 2;
            let money = "";
            decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
            if (_cne) {
              money = _cne;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.cneqntsum = zgds;
            }
            if (_clz) {
              money = _clz;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.clzqntsum = zgds;
            }
            if (_de) {
              money = _de;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.deqntsum = zgds;
            }
            if (_yqd) {
              money = _yqd;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.yqdqntsum = zgds;
            }
            if (_ccfh) {
              money = _ccfh;
              money =
                // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                  decimal
                ) + "";
              let d = money.split(".")[0].split("").reverse();
              let y = money.split(".")[1];
              let q = "";
              for (let i = 0; i < d.length; i++) {
                q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
              }
              //转过的数
              let zgds = q.split("").reverse().join("") + "." + y;
              this.topTablesum.ccfhqntsum = zgds;
            }
          }
        });
        this.sumNumber = this.tableArr.length;
        }
     
      } 
      //产品系列预测
      else if (this.forcastType == 1) {
        this.loading = true;
        let params = {
          appraduserlookup___0_lkid:this.appraduserlookup___0_lkid,
          forenum: this.forenum,
          foreyear: this.foreyear,
          foremon: this.foremon,
          foretype: "1",
          foreper: this.foreper,
          foredis: this.foredis,
          selectab: this.selectab,
          cpxl: this.cpxlId,
          foreperiod: this.foreperiod,
        };
        
        let res = {}
        try {
           res = await viewForecastAllCpxl(params);
        } catch (error) {
          this.isQuery = true;
        }
        //缺省页
        if (res?.data === "" || res?.data === null || res?.data === undefined) {
          this.isQuery = true;
        } else {
          this.isQuery = false;
        }
        this.qdyearlist = res?.data?.qdyearlist;
        setTimeout(() => {
          this.loading = false;
          this.fullscreenLoading = false;
        }, 500);
        // 判断是否是经理
        this.ismanager=res.data.ismanager
        //如果下级有产品系列的话调用rootmap否则调用forecastdatas
        if (res.data && res.data.rootmap && res.data.rootmap.length > 0) {
          this.forecastdatasAll = res.data.forecastdatas;
          res.data &&
            res.data.rootmap.forEach((item) => {
              this.cnearr = [];
              this.dearr = [];
              this.ssdearr = [];
              this.cnearr = [];
              this.sscnearr = [];
              this.yqdarr = [];
              this.ssyqdarr = [];
              this.ccfharr = [];
              this.ssccfharr = [];
              this.clzarr = [];
              this.ssclzarr = [];
              this.Sdearr = [];
              this.Scnearr = [];
              this.Syqdarr = [];
              this.Sccfharr = [];
              this.Sclzarr = [];
              this.ssSdearr = [];
              this.ssScnearr = [];
              this.ssSyqdarr = [];
              this.ssSccfharr = [];
              this.ssSclzarr = [];
              //产品预测不可编辑总额
              this.$set(item, "isEddit", false);
              this.$set(item, "isFilter", false);
              this.tableArr.push(item);
              if (this.foreperiod === "0") {
                this.$set(
                  item,
                  "date",
                  this.$i18n.t('c962',{year:item.forecastyear,month:item.forecastperiod})
                );
              } else {
                this.$set(
                  item,
                  "date",
                  this.$i18n.t('c961',{year:item.forecastyear,quarter:item.forecastperiod})
                );
              }
              this.$set(item, "zhuantu", false);
              //显示文字
              item.children.forEach((ite) => {
                this.cnearr = [];
                this.dearr = [];
                this.cnearr = [];
                this.yqdarr = [];
                this.ccfharr = [];
                this.clzarr = [];
                this.Sdearr = [];
                this.Scnearr = [];
                this.Syqdarr = [];
                this.Sccfharr = [];
                this.Sclzarr = [];
                this.$set(ite, "forecastyear", item.forecastyear);
                this.$set(ite, "forecastperiod", item.forecastperiod);
                this.$set(ite, "isFilter", true);
                this.$set(ite, "isEddit", false);
                //  this.forecastdatasAll.forEach((edd) => {
                //   edd.children.forEach((eddd) => {
                //     if (
                //       eddd.series === ite.series &&
                //       eddd.forecastperiod === ite.forecastperiod
                //     ) {
                //       ite.isrepcommits = eddd.isrepcommits;
                //       ite.isrepbests = eddd.isrepbests;
                //       ite.isrepbestsqnt = eddd.isrepbestsqnt;
                //       ite.isrepcommitsqnt = eddd.isrepcommitsqnt;
                //     }
                //   });
                // });
                // this.$set(ite, "cpxlname", ite.series)
                if (ite.series === " ") {
                  ite.series = this.$i18n.t("label.tabpage.nothingz");
                }
                // } else if (ite.series === "bba2013012043178nCub") {
                //   this.cpxlname = "产品系列一";
                // } else if (ite.series === "bba201301204319IpQeM") {
                //   this.cpxlname = "产品系列二";
                // } else if (ite.series === "bba201301204318453zs") {
                //   this.cpxlname = "产品系列三";
                // }
                //添加产品系列
                this.$set(ite, "date", ite.series);
                //添加跳转箭头
                this.$set(ite, "zhuantu", false);
                ite.children.forEach((it) => {
                  this.$set(it, "isFilter", true);
                  this.$set(it, "isEddit", true);
                  this.$set(it, "zhuantu", true);
                  this.$set(it, "series", ite.series);
                  //添加用户名
                  this.$set(it, "date", it.username);
                });
                //去掉自己的跳转箭头
                ite.children[ite.children.length - 1].zhuantu = false;
                ite.children[ite.children.length - 1].isEddit = false;
                // ite.children[ite.children.length - 1].commits = ite.children[ite.children.length - 1].commitamt;
                // ite.children[ite.children.length - 1].bests = ite.children[ite.children.length - 1].bestcaseamt;
                //加头像
                ite.children.forEach((it) => {
                  if (it.isrepcommits === "self") {
                    it.isrepcommits = false;
                  }
                  this.$set(it, "isImages", true);
                });
                //整合周期总数
                if (this.forenum == "0") {
                  let de = ite.quotas.split(" ");
                  let yqd = ite.closeds.split(" ");
                  let cne = ite.commits.split(" ");
                  let ccfh = ite.bests.split(" ");
                  let clz = ite.pipelines.split(" ");
                  //定额去掉千分符相加
                  let _de = de[1];
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  this.ssdearr.push(_de);
                  let desum = 0;
                  this.ssdearr.forEach((ite) => {
                    desum += +ite;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd[1];
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  this.ssyqdarr.push(_yqd);
                  let yqdsum = 0;
                  this.ssyqdarr.forEach((ite) => {
                    yqdsum += +ite;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne[1];
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  this.sscnearr.push(_cne);
                  let cnesum = 0;
                  this.sscnearr.forEach((ite) => {
                    cnesum += +ite;
                  });
                  _cne = cnesum.toString();
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh[1];
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  this.ssccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  this.ssccfharr.forEach((ite) => {
                    ccfhsum += +ite;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz[1];
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  this.ssclzarr.push(_clz);
                  let clzsum = 0;
                  this.ssclzarr.forEach((ite) => {
                    clzsum += +ite;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.commits = cne[0] + " " + zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.pipelines = clz[0] + " " + zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.quotas = de[0] + " " + zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.closeds = yqd[0] + " " + zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.bests = ccfh[0] + " " + zgds;
                  }
                } else {
                  //整合周期总数
                  let de = ite.quotaqnt;
                  let yqd = ite.closedsqnt;
                  let cne = ite.commitsqnt;
                  let ccfh = ite.bestsqnt;
                  let clz = ite.pipelinesqnt;
                  //定额去掉千分符相加
                  let _de = de;
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  this.ssSdearr.push(_de);
                  let desum = 0;
                  this.ssSdearr.forEach((ite) => {
                    desum += +ite;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd;
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  this.ssSyqdarr.push(_yqd);
                  let yqdsum = 0;
                  this.ssSyqdarr.forEach((ite) => {
                    yqdsum += +ite;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne;
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  this.ssScnearr.push(_cne);
                  let cnesum = 0;
                  this.ssScnearr.forEach((ite) => {
                    cnesum += +ite;
                  });
                  _cne = cnesum.toString();
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh;
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  this.ssSccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  this.ssSccfharr.forEach((ite) => {
                    ccfhsum += +ite;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz;
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  this.ssSclzarr.push(_clz);
                  let clzsum = 0;
                  this.ssSclzarr.forEach((ite) => {
                    clzsum += +ite;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.commitsqnt = zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.pipelinesqnt = zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.quotaqnt = zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.closedsqnt = zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    item.bestsqnt = zgds;
                  }
                }
              });
              this.zhongs.forEach((sum) => {
                //整合周期总数
                if (this.forenum == "0") {
                  //整合周期总数
                  let de = item.quotas.split(" ");
                  let yqd = item.closeds.split(" ");
                  let cne = item.commits.split(" ");
                  let ccfh = item.bests.split(" ");
                  let clz = item.pipelines.split(" ");
                  //定额去掉千分符相加
                  let _de = de[1];
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  sum.dearr.push(_de);
                  let desum = 0;
                  sum.dearr.forEach((item) => {
                    desum += +item;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd[1];
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  sum.yqdarr.push(_yqd);
                  let yqdsum = 0;
                  sum.yqdarr.forEach((item) => {
                    yqdsum += +item;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne[1];
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  sum.cnearr.push(_cne);
                  let cnesum = 0;
                  sum.cnearr.forEach((item) => {
                    cnesum += +item;
                  });
                  _cne = cnesum.toString();
                  // 
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh[1];
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  sum.ccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  sum.ccfharr.forEach((item) => {
                    ccfhsum += +item;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz[1];
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  sum.clzarr.push(_clz);
                  let clzsum = 0;
                  sum.clzarr.forEach((item) => {
                    clzsum += +item;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.cnesum = cne[0] + " " + zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.clzsum = clz[0] + " " + zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.desum = de[0] + " " + zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.yqdsum = yqd[0] + " " + zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.ccfhsum = ccfh[0] + " " + zgds;
                  }
                } else {
                  //整合周期总数
                  let de = item.quotaqnt.split(" ");
                  let yqd = item.closedsqnt.split(" ");
                  let cne = item.commitsqnt.split(" ");
                  let ccfh = item.bestsqnt.split(" ");
                  let clz = item.pipelinesqnt.split(" ");
                  //定额去掉千分符相加
                  let _de = de;
                  _de = _de.toString();
                  _de = _de.replace(/,/gi, "");
                  sum.Sdearr.push(_de);
                  let desum = 0;
                  sum.Sdearr.forEach((item) => {
                    desum += +item;
                  });
                  _de = desum.toString();
                  //已签单去掉千分符相加
                  let _yqd = yqd;
                  _yqd = _yqd.toString();
                  _yqd = _yqd.replace(/,/gi, "");
                  sum.Syqdarr.push(_yqd);
                  let yqdsum = 0;
                  sum.Syqdarr.forEach((item) => {
                    yqdsum += +item;
                  });
                  _yqd = yqdsum.toString();
                  //承诺额去掉千分符相加
                  let _cne = cne;
                  _cne = _cne.toString();
                  _cne = _cne.replace(/,/gi, "");
                  sum.Scnearr.push(_cne);
                  let cnesum = 0;
                  sum.Scnearr.forEach((item) => {
                    cnesum += +item;
                  });
                  _cne = cnesum.toString();
                  //超长发挥去掉千分符相加
                  let _ccfh = ccfh;
                  _ccfh = _ccfh.toString();
                  _ccfh = _ccfh.replace(/,/gi, "");
                  sum.Sccfharr.push(_ccfh);
                  let ccfhsum = 0;
                  sum.Sccfharr.forEach((item) => {
                    ccfhsum += +item;
                  });
                  _ccfh = ccfhsum.toString();
                  //处理中去掉千分符相加
                  let _clz = clz;
                  _clz = _clz.toString();
                  _clz = _clz.replace(/,/gi, "");
                  sum.Sclzarr.push(_clz);
                  let clzsum = 0;
                  sum.Sclzarr.forEach((item) => {
                    clzsum += +item;
                  });
                  _clz = clzsum.toString();
                  // 定额加千分符
                  let decimal = 2;
                  let money = "";
                  decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                  if (_cne) {
                    money = _cne;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.cneqntsum = zgds;
                  }
                  if (_clz) {
                    money = _clz;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.clzqntsum = zgds;
                  }
                  if (_de) {
                    money = _de;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.deqntsum = zgds;
                  }
                  if (_yqd) {
                    money = _yqd;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.yqdqntsum = zgds;
                  }
                  if (_ccfh) {
                    money = _ccfh;
                    money =
                      // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                        decimal
                      ) + "";
                    let d = money.split(".")[0].split("").reverse();
                    let y = money.split(".")[1];
                    let q = "";
                    for (let i = 0; i < d.length; i++) {
                      q +=
                        d[i] +
                        ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                    }
                    //转过的数
                    let zgds = q.split("").reverse().join("") + "." + y;
                    this.topTablesum.ccfhqntsum = zgds;
                  }
                }
              });
              this.sumNumber = this.tableArr.length;
            });
        } else {
          this.tableArr = res.data.forecastdatas;
          this.tableArr.forEach((item) => {
            this.cnearr = [];
            this.dearr = [];
            this.cnearr = [];
            this.yqdarr = [];
            this.ccfharr = [];
            this.clzarr = [];
            this.Sdearr = [];
            this.Scnearr = [];
            this.Syqdarr = [];
            this.Sccfharr = [];
            this.Sclzarr = [];
            //整合总额不可点击筛选
            this.$set(item, "isFilter", false);
            //产品预测不可编辑总额
            this.$set(item, "isEddit", false);
            if (this.foreperiod === "0") {
              this.$set(
                item,
                "date",
                `${item.year}${this.$i18n.t("year")}${
                  item.period
                }${this.$i18n.t("month")}`
              );
            } else {
              this.$set(
                item,
                "date",
                `${item.year}${this.$i18n.t("year")}${this.$i18n.t(
                  "label.sales.dashboard.number"
                )}${item.period}${this.$i18n.t(
                  "label.account.quarter"
                )}`
              );
            }
            item.children.forEach((ite) => {
              this.$set(ite, "forecastperiod", item.period);
              this.$set(ite, "isFilter", true);
              this.$set(ite, "isEddit", true);
              //展示产品系列层级
              if (ite.series === " ") {
                ite.series = this.$i18n.t("label.tabpage.nothingz");
                // ite.series = "无";
              }
              // if (ite.series === "") {
              //   this.cpxlname = "无";
              // } else if (ite.series === "bba2013012043178nCub") {
              //   this.cpxlname = "产品系列一";
              // } else if (ite.series === "bba201301204319IpQeM") {
              //   this.cpxlname = "产品系列二";
              // } else if (ite.series === "bba201301204318453zs") {
              //   this.cpxlname = "产品系列三";
              // }
              //添加产品系列
              this.$set(ite, "date", ite.series);
              //加头像;
              if (ite.children) {
                this.$set(ite, "isFilter", true);
                this.$set(ite, "isEddit", true);
                this.$set(ite, "series", ite.series);
                ite.children.forEach((it) => {
                  if (it.isrepcommits === "self") {
                    it.isrepcommits = false;
                  }
                  this.$set(it, "isImages", true);
                });
              }
              //整合周期总数
              if (this.forenum == "0") {
                //整合周期总数
                let de = ite.quotas.split(" ");
                let yqd = ite.closeds.split(" ");
                let cne = ite.commits.split(" ");
                let ccfh = ite.bests.split(" ");
                let clz = ite.pipelines.split(" ");
                //定额去掉千分符相加
                let _de = de[1];
                _de = _de.toString();
                _de = _de.replace(/,/gi, "");
                this.dearr.push(_de);
                let desum = 0;
                this.dearr.forEach((ite) => {
                  desum += +ite;
                });
                _de = desum.toString();
                //已签单去掉千分符相加
                let _yqd = yqd[1];
                _yqd = _yqd.toString();
                _yqd = _yqd.replace(/,/gi, "");
                this.yqdarr.push(_yqd);
                let yqdsum = 0;
                this.yqdarr.forEach((ite) => {
                  yqdsum += +ite;
                });
                _yqd = yqdsum.toString();
                //承诺额去掉千分符相加
                let _cne = cne[1];
                _cne = _cne.toString();
                _cne = _cne.replace(/,/gi, "");
                this.cnearr.push(_cne);
                let cnesum = 0;
                this.cnearr.forEach((ite) => {
                  cnesum += +ite;
                });
                _cne = cnesum.toString();
                // 
                //超长发挥去掉千分符相加
                let _ccfh = ccfh[1];
                _ccfh = _ccfh.toString();
                _ccfh = _ccfh.replace(/,/gi, "");
                this.ccfharr.push(_ccfh);
                let ccfhsum = 0;
                this.ccfharr.forEach((ite) => {
                  ccfhsum += +ite;
                });
                _ccfh = ccfhsum.toString();
                //处理中去掉千分符相加
                let _clz = clz[1];
                _clz = _clz.toString();
                _clz = _clz.replace(/,/gi, "");
                this.clzarr.push(_clz);
                let clzsum = 0;
                this.clzarr.forEach((ite) => {
                  clzsum += +ite;
                });
                _clz = clzsum.toString();
                // 定额加千分符
                let decimal = 2;
                let money = "";
                decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                if (_cne) {
                  money = _cne;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.commits = cne[0] + " " + zgds;
                }
                if (_clz) {
                  money = _clz;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.pipelines = clz[0] + " " + zgds;
                }
                if (_de) {
                  money = _de;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.quotas = de[0] + " " + zgds;
                }
                if (_yqd) {
                  money = _yqd;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.closeds = yqd[0] + " " + zgds;
                }
                if (_ccfh) {
                  money = _ccfh;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.bests = ccfh[0] + " " + zgds;
                }
              } else {
                //整合周期总数
                let de = ite.quotaqnt.split(" ");
                let yqd = ite.closedsqnt.split(" ");
                let cne = ite.commitsqnt.split(" ");
                let ccfh = ite.bestsqnt.split(" ");
                let clz = ite.pipelinesqnt.split(" ");
                //定额去掉千分符相加
                let _de = de[1];
                _de = _de.toString();
                _de = _de.replace(/,/gi, "");
                this.Sdearr.push(_de);
                let desum = 0;
                this.Sdearr.forEach((ite) => {
                  desum += +ite;
                });
                _de = desum.toString();
                //已签单去掉千分符相加
                let _yqd = yqd[1];
                _yqd = _yqd.toString();
                _yqd = _yqd.replace(/,/gi, "");
                this.Syqdarr.push(_yqd);
                let yqdsum = 0;
                this.Syqdarr.forEach((ite) => {
                  yqdsum += +ite;
                });
                _yqd = yqdsum.toString();
                //承诺额去掉千分符相加
                let _cne = cne[1];
                _cne = _cne.toString();
                _cne = _cne.replace(/,/gi, "");
                this.Scnearr.push(_cne);
                let cnesum = 0;
                this.Scnearr.forEach((ite) => {
                  cnesum += +ite;
                });
                _cne = cnesum.toString();
                //超长发挥去掉千分符相加
                let _ccfh = ccfh[1];
                _ccfh = _ccfh.toString();
                _ccfh = _ccfh.replace(/,/gi, "");
                this.Sccfharr.push(_ccfh);
                let ccfhsum = 0;
                this.Sccfharr.forEach((ite) => {
                  ccfhsum += +ite;
                });
                _ccfh = ccfhsum.toString();
                //处理中去掉千分符相加
                let _clz = clz[1];
                _clz = _clz.toString();
                _clz = _clz.replace(/,/gi, "");
                this.Sclzarr.push(_clz);
                let clzsum = 0;
                this.Sclzarr.forEach((ite) => {
                  clzsum += +ite;
                });
                _clz = clzsum.toString();
                // 定额加千分符
                let decimal = 2;
                let money = "";
                decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                if (_cne) {
                  money = _cne;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.commitsqnt = zgds;
                }
                if (_clz) {
                  money = _clz;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.pipelinesqnt = zgds;
                }
                if (_de) {
                  money = _de;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.quotaqnt = zgds;
                }
                if (_yqd) {
                  money = _yqd;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.closedsqnt = zgds;
                }
                if (_ccfh) {
                  money = _ccfh;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  item.bestsqnt = zgds;
                }
              }
            });
            this.zhongs.forEach((sum) => {
              //整合周期总数
              if (this.forenum == "0") {
                //整合周期总数
                let de = item.quotas.split(" ");
                let yqd = item.closeds.split(" ");
                let cne = item.commits.split(" ");
                let ccfh = item.bests.split(" ");
                let clz = item.pipelines.split(" ");
                //定额去掉千分符相加
                let _de = de[1];
                _de = _de.toString();
                _de = _de.replace(/,/gi, "");
                sum.dearr.push(_de);
                let desum = 0;
                sum.dearr.forEach((item) => {
                  desum += +item;
                });
                _de = desum.toString();
                //已签单去掉千分符相加
                let _yqd = yqd[1];
                _yqd = _yqd.toString();
                _yqd = _yqd.replace(/,/gi, "");
                sum.yqdarr.push(_yqd);
                let yqdsum = 0;
                sum.yqdarr.forEach((item) => {
                  yqdsum += +item;
                });
                _yqd = yqdsum.toString();
                //承诺额去掉千分符相加
                let _cne = cne[1];
                _cne = _cne.toString();
                _cne = _cne.replace(/,/gi, "");
                sum.cnearr.push(_cne);
                let cnesum = 0;
                sum.cnearr.forEach((item) => {
                  cnesum += +item;
                });
                _cne = cnesum.toString();
                // 
                //超长发挥去掉千分符相加
                let _ccfh = ccfh[1];
                _ccfh = _ccfh.toString();
                _ccfh = _ccfh.replace(/,/gi, "");
                sum.ccfharr.push(_ccfh);
                let ccfhsum = 0;
                sum.ccfharr.forEach((item) => {
                  ccfhsum += +item;
                });
                _ccfh = ccfhsum.toString();
                //处理中去掉千分符相加
                let _clz = clz[1];
                _clz = _clz.toString();
                _clz = _clz.replace(/,/gi, "");
                sum.clzarr.push(_clz);
                let clzsum = 0;
                sum.clzarr.forEach((item) => {
                  clzsum += +item;
                });
                _clz = clzsum.toString();

                // 定额加千分符
                let decimal = 2;
                let money = "";
                decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                if (_cne) {
                  money = _cne;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.cnesum = cne[0] + " " + zgds;
                }
                if (_clz) {
                  money = _clz;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.clzsum = clz[0] + " " + zgds;
                }
                if (_de) {
                  money = _de;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.desum = de[0] + " " + zgds;
                }
                if (_yqd) {
                  money = _yqd;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.yqdsum = yqd[0] + " " + zgds;
                }
                if (_ccfh) {
                  money = _ccfh;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.ccfhsum = ccfh[0] + " " + zgds;
                }
              } else {
                //整合周期总数
                let de = item.quotaqnt.split(" ");
                let yqd = item.closedsqnt.split(" ");
                let cne = item.commitsqnt.split(" ");
                let ccfh = item.bestsqnt.split(" ");
                let clz = item.pipelinesqnt.split(" ");
                //定额去掉千分符相加
                let _de = de;
                _de = _de.toString();
                _de = _de.replace(/,/gi, "");
                sum.Sdearr.push(_de);
                let desum = 0;
                sum.Sdearr.forEach((item) => {
                  desum += +item;
                });
                _de = desum.toString();
                //已签单去掉千分符相加
                let _yqd = yqd;
                _yqd = _yqd.toString();
                _yqd = _yqd.replace(/,/gi, "");
                sum.Syqdarr.push(_yqd);
                let yqdsum = 0;
                sum.Syqdarr.forEach((item) => {
                  yqdsum += +item;
                });
                _yqd = yqdsum.toString();
                //承诺额去掉千分符相加
                let _cne = cne;
                _cne = _cne.toString();
                _cne = _cne.replace(/,/gi, "");
                sum.Scnearr.push(_cne);
                let cnesum = 0;
                sum.Scnearr.forEach((item) => {
                  cnesum += +item;
                });
                _cne = cnesum.toString();
                //超长发挥去掉千分符相加
                let _ccfh = ccfh;
                _ccfh = _ccfh.toString();
                _ccfh = _ccfh.replace(/,/gi, "");
                sum.Sccfharr.push(_ccfh);
                let ccfhsum = 0;
                sum.Sccfharr.forEach((item) => {
                  ccfhsum += +item;
                });
                _ccfh = ccfhsum.toString();
                //处理中去掉千分符相加
                let _clz = clz;
                _clz = _clz.toString();
                _clz = _clz.replace(/,/gi, "");
                sum.Sclzarr.push(_clz);
                let clzsum = 0;
                sum.Sclzarr.forEach((item) => {
                  clzsum += +item;
                });
                _clz = clzsum.toString();
                // 定额加千分符
                let decimal = 2;
                let money = "";
                decimal = decimal > 0 && decimal <= 20 ? decimal : 2;
                if (_cne) {
                  money = _cne;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.cneqntsum = zgds;
                }
                if (_clz) {
                  money = _clz;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.clzqntsum = zgds;
                }
                if (_de) {
                  money = _de;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.deqntsum = zgds;
                }
                if (_yqd) {
                  money = _yqd;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.yqdqntsum = zgds;
                }
                if (_ccfh) {
                  money = _ccfh;
                  money =
                    // eslint-disable-next-line no-useless-escape
                      parseFloat((money + "").replace(/[^\d\.-]/g, "")).toFixed(
                      decimal
                    ) + "";
                  let d = money.split(".")[0].split("").reverse();
                  let y = money.split(".")[1];
                  let q = "";
                  for (let i = 0; i < d.length; i++) {
                    q +=
                      d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
                  }
                  //转过的数
                  let zgds = q.split("").reverse().join("") + "." + y;
                  this.topTablesum.ccfhqntsum = zgds;
                }
              }
            });
          });

          this.sumNumber = this.tableArr.length;
        }
      }
    },
    //远程搜索进行用户更换
    sx(serarchS) {
      this.appraduserlookup___0_lkid = serarchS.serarchId;
      this.foreuser = serarchS.serarchName;
      this.realName = serarchS.serarchName;
      this.userId=serarchS.serarchId;
      this.tableArr = [];
      this.tableArrChildren = [];
      // if (this.realName !== this.foreuser) {
      //   this.tiaoz = true;
      // } else {
      //   this.tiaoz = false;
      // }
      this.refresh();
      this.serarchForeTo = false;
    },
    // 获取用户信息
    async getUserName() {
      let res = await getUserInfo();
      this.userId = res.data.userId;
      this.realName = res.data.userName;
      this.myName=res.data.userName;
      this.myId=res.data.userId;
      this.foreuser = res.data.userName;
      this.appraduserlookup___0_lkid = res.data.userId;
      this.isAdmin = JSON.parse(res.data.manageSetup);
      this.oldImgSrc = `${VueCookies.get(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=${
        this.userId
      }&binding=${this.$CCDK.CCToken.getToken()}`;
    },
    //设置
    getForecastTypes(item) {
      // 业务机会收入
      if (item.value == 1) {
        this.income = true;
        this.amount = false;
        this.forenum = "0";
        this.yhuoj = "收入";
        this.forecastTypes[0].icon = this.pitch;
        this.forecastTypes[1].icon = "";
        this.tableArr = [];
        this.tableArrChildren = [];
        this.refresh();
      } 
      // 业务机会数量
      else if (item.value == 2) {
        this.amount = true;
        this.income = false;
        this.forenum = "1";
        this.yhuoj = "数量";
        this.forecastTypes[1].icon = this.pitch;
        this.forecastTypes[0].icon = "";
        this.tableArr = [];
        this.tableArrChildren = [];
        this.refresh();
      }
    },
    getForecastOptions(item) {
      //显示定额
      if (item.value == 1 && item.icon !== "") {
        this.quota = false;
        item.icon = "";
      } else if (item.value == 1 && item.icon == "") {
        item.icon = this.pitch;
        this.quota = true;
      }
      //显示定额完成百分比
      if (item.value == 2 && item.icon !== "") {
        this.percents = false;
        item.icon = "";
      } else if (item.value == 2 && item.icon == "") {
        item.icon = this.pitch;
        this.percents = true;
      }
      //显示全部为零值的行
      if (item.value == 3 && item.icon !== "") {
        item.icon = "";
      } else if (item.value == 3 && item.icon == "") {
        item.icon = this.pitch;
      }
    },
    //远程搜索
    seraaKda() {
      getLookupResults({
        lktp: this.lktp,
        lknm: this.lknm,
        lksrch: this.lksrch,
        lkdp: this.lkdp,
      }).then((res) => {
        this.serarchList = res.data.objlist.map((item) => {
          return { value: item.id, label: item.name, id: item.id };
        });
        // this.optionList = res.data.objlist;
        this.optionListfa = false;
      });
    },
    //远程搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loadingg = true;
        setTimeout(() => {
          this.loadingg = false;
          this.optionListfa = true;
          this.optionList = this.serarchList.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 800);
      } else {
        this.optionList = [];
      }
    },
    //跳转用户
    changeEvent() {
      this.appraduserlookup___0_lkid = this.svalue;
      this.userId= this.svalue;
      let params = {
        userId: this.appraduserlookup___0_lkid,
      };
      userMessage(params).then((res) => {
        this.foreuser = res.data.name;
        this.realName = res.data.name;
        if (this.realName !== this.foreuser) {
          this.tiaoz = false;
        } else {
          this.tiaoz = false;
        }
      });
      this.tableArr = [];
      this.tableArrChildren = [];
      this.refresh();
      // this.onclose();
      // this.getViewForecast();
      // this.getForecastQueryTabList();
    },
    alloptionList() {
      getLookupResults({
        lktp: this.lktp,
        lknm: this.lknm,
        lksrch: this.lksrch,
        lkdp: this.lkdp,
      }).then((res) => {
        this.optionListt = res.data.objlist;
      });
    },
    gomee(){
      this.appraduserlookup___0_lkid = this.myId;
      this.foreuser = this.myName;
      this.tableArr = [];
      this.tableArrChildren = [];
      // if (this.realName !== this.foreuser) {
      //   this.tiaoz = true;
      // } else {
      //   this.tiaoz = false;
      // }
      this.refresh();
      // this.getViewForecast();
      // this.onclose();
      // this.getForecastQueryTabList();
    },
    //跳转回当前用户的预测
    gome() {
      this.appraduserlookup___0_lkid = this.userId;
      this.foreuser = this.realName;
      this.tableArr = [];
      this.tableArrChildren = [];
      if (this.realName !== this.foreuser) {
        this.tiaoz = true;
      } else {
        this.tiaoz = false;
      }
      this.refresh();
      // this.getViewForecast();
      // this.onclose();
      // this.getForecastQueryTabList();
    },
    //设置列表显示隐藏
    clickSet() {
      this.setTo = !this.setTo;
      this.searchTo = false;
    },
    //设置共享列表显示隐藏
    clickSearch() {
      this.searchTo = !this.searchTo;
      this.setTo = false;
    },
    // 点击页面事件 隐藏需要隐藏的区域
    onclose() {
      this.searchTo = false;
      this.setTo = false;
      this.searchToo = false;
    },
    //设置范围
    scopeFore() {
      this.scopeForeTo = true;
      this.$refs.scopeFore.preSettings();
    },
    //保存预测
    async getForecastSubmit() {
      let params = {
        userid: this.userId,
        forenum: this.forenum,
        foreyear: this.foreyear,
        foremon: this.foremon,
        foreper: this.foreper,
        foreperiod: this.foreperiod,
      };
      await getForecastSubmit({ params });
    },
    //确定修改预测范围
    scopeForeConfirm(form) {
      this.foreyear = form.nowForeyear.toString();
      this.foremon = form.nowForemon;
      this.foreper = form.nowForeper;
      this.refresh();
      this.scopeForeCancel();
      this.$message({
        showClose: true,
        message: `${this.$i18n.t("message.modify.success")}`,
        type: "success",
      });
      // 
    },
    // 关闭设置范围
    scopeForeCancel() {
      this.scopeForeTo = false;
    },
    //搜索
    serarchFore() {
      this.serarchForeTo = true;
    },
    //关闭搜索
    serarchForeCancel() {
      this.serarchForeTo = false;
    },
    // 刷新
    refresh() {
      this.lastRefresh = new Date();
      this.dearr = []; //收入定额周期集合
      this.cnearr = []; //收入承诺额周期集合
      this.yqdarr = []; //收入已签单周期集合
      this.ccfharr = []; //收入超长发挥周期集合
      this.clzarr = []; //收入处理中周期集合
      this.Sdearr = []; //收入定额周期集合
      this.Scnearr = []; //数量承诺额周期集合
      this.Syqdarr = []; //数量已签单周期集合
      this.Sccfharr = []; //数量超长发挥周期集合
      this.Sclzarr = []; //数量处理中周期集合
      this.ssdearr = [];
      this.sscnearr = [];
      this.ssyqdarr = [];
      this.ssccfharr = [];
      this.ssclzarr = [];
      this.ssSdearr = [];
      this.ssScnearr = [];
      this.ssSyqdarr = [];
      this.ssSccfharr = [];
      this.ssSclzarr = [];
      this.tableArr = [];
      this.zhongs.forEach((item) => {
        item.dearr = []; //收入定额周期集合
        item.cnearr = []; //收入承诺额周期集合
        item.yqdarr = []; //收入已签单周期集合
        item.ccfharr = []; //收入超长发挥周期集合
        item.clzarr = []; //收入处理中周期集合
        item.Sdearr = []; //收入定额周期集合
        item.Scnearr = []; //数量承诺额周期集合
        item.Syqdarr = []; //数量已签单周期集合
        item.Sccfharr = []; //数量超长发挥周期集合
        item.Sclzarr = []; //数量处理中周期集合
      });
      this.tableArrChildren = [];
      this.tableDetail = null;
      this.tableDet = false;
      this.getViewForecast();
      this.getForecastQueryTabList();
      this.onclose();
    },
  },

  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 1;
}
.forecast_box {
  padding: 10px;
  height: 100%;
  font-size: 12px !important;
  color: #080707 !important;
}
.noPermissionBox {
  // width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% - 136px);
  left: calc(50% - 176.5px);

  .noPermissionTip {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}
.forecastList {
  position: fixed;
  width: calc(100% - 71px);
  height: 100vh;
  background-color: #fff;
  border-radius: 4px;
  .forecast_content {
    user-select: none;
    height: 100%;
    background: #fafaf9;
    .forecast_top {
      height: 69px;
      background: #f5f5f5;
      border: 1px solid #dedcda;
      border-radius: 3px 3px 0 0;
      .top_message {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        .top_tltle {
          position: relative;
          display: flex;
          align-items: flex-start;
          .user_avatar {
            img {
              width: 43px;
              height: 41px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .user_zi {
            margin-bottom: 0;
            margin-left: 8px;
          }
          .forecast_type {
            height: 16px;
            color: #333333;
          }
          .forecast_yh {
            .username {
              height: 30px;
              font-size: 16px;
              span:nth-child(2) {
                margin-left: 8px;
              }
            }
            .nn:hover {
              border-bottom: 1px solid;
              cursor: pointer;
            }
            svg {
              width: 18.6px;
              height: 16.7px;
            }
          }
        }
        .search_ball {
          position: absolute;
          z-index: 999;
          width: 439px;
          background: #ffffff;
          border: 1px solid #dddbda;
          box-shadow: 0 8px 13px 3px rgba(0, 0, 0, 0.3);
          border-radius: 3px;
          left: 0px;
          top: 46px;
          padding: 10px 20px;
          ::v-deep .el-select .el-input__inner {
            width: 399px;
            height: 30px;
            border: 1px solid #e3e2e2;
            border-radius: 3px;
            border-radius: 3px;
            margin-left: -10px;
          }
          .search_list {
            margin: 10px;
            overflow-y: auto;
            margin-bottom: 0;
          }
          span {
            display: flex;
            padding-top: 10px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
          }

          p {
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #080707;
            letter-spacing: 0;
            margin: 10px 10px;
          }
        }
        .right_infos {
          .infos_box {
            position: relative;
            display: flex;
            justify-content: flex-end;
            .refresh {
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 1px solid #c8c6c6;
              cursor: pointer;
              border-radius: 3px;
              align-items: center;
              justify-content: center;
              display: flex;
              margin-left: 10px;
              ::v-deep .el-icon-setting {
                width: 16.6px;
                height: 16.6px;
                text-align: center;
              }
            }
            .refresh:hover {
              color: skyblue;
            }

            .set {
              width: 30px;
              height: 30px;
              background: #ffffff;
              border: 1px solid #c8c6c6;
              cursor: pointer;
              border-radius: 3px;
              align-items: center;
              justify-content: center;
              display: flex;
              margin-left: 10px;
              i {
                width: 12px;
                height: 12.4px;
                text-align: center;
              }
            }
            .set:hover {
              color: skyblue;
            }
          }
          .nnn {
            top: 0px;
            position: absolute;
            width: 30px;
            height: 36px;
          }
          .set_bounce {
            position: absolute;
            z-index: 999;
            display: flex;
            flex-direction: column;
            width: 247px;
            top: 36px;
            right: 0px;
            background: #ffffff;
            border: 1px solid #dddbda;
            box-shadow: 0 8px 13px 3px rgba(0, 0, 0, 0.3);
            .parent {
              z-index: 999;
            }
            .parent > li {
              border-radius: 3px;
              padding: 7px 16px;
              font-family: SourceHanSansCN-Medium;
              font-size: 14px;
              color: #888888;
              letter-spacing: 0;
            }
            .parent > li:nth-child(2) {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              color: #080707;
              letter-spacing: 0;
            }
            .parent > li:nth-child(2):hover {
              background: #eee;
            }
            .elements > li {
              font-family: SourceHanSansCN-Regular;
              padding: 7px 40px;
              font-size: 14px;
              color: #080707;
              letter-spacing: 0;
              i {
                margin-left: -24px;
                padding-right: 20px;
              }
            }
            .elements > li:hover {
              background: #eee;
            }
          }
        }
      }
      .gome {
        cursor: pointer;
      }
      .forecast_related {
        display: flex;
        height: 21px;
        background: #fafaf9;
        border-top: 1px solid #dedcda;
        p {
          cursor: pointer;
          color: #006dcc;
          margin-left: 10px;
        }
        span {
          margin-left: 10px;
        }
      }
      .timer {
        display: flex;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #333333;
        letter-spacing: 0;
        padding-top: 6px;
        justify-content: flex-end;
      }
    }
    ::v-deep .el-table {
      tr,
      td {
        border-right: 0;
      }
      .el-table__footer-wrapper:hover {
        list-style: none;
      }
      // td:hover {
      //   background: #fff;
      // }
      .el-table__body-wrapper .el-table__row--level-1 td:nth-child(n+3):hover{
        background: #fff;
        cursor: pointer;
      }
      .el-table__body-wrapper .el-table__row--level-2 td:nth-child(n+3):hover{
        background: #fff;
        cursor: pointer;
      }
      th {
        background: #fafaf9;
      }
      th:hover {
        // background: #fff;
      }
    }
    // 查找下拉框不显示下拉图标
    // 查找下拉框不显示下拉图标
    ::v-deep .no-suffix .el-input__suffix {
      right: 25px;
      .el-icon-arrow-up {
        display: none;
      }
    }
    ::v-deep .el-table_1_column_1 .cell {
      display: flex;
      justify-content: flex-start !important;
      line-height: 20px;
    }
    ::v-deep .el-table_2_column_8 .cell {
      display: flex;
      justify-content: flex-start !important;
      line-height: 20px;
    }
    ::v-deep .el-table_3_column_7 .cell {
      display: flex;
      justify-content: flex-start !important;
      line-height: 20px;
    }
    ::v-deep .el-table__row .el-table__row--level-0 > td {
      display: flex;
      justify-content: flex-start !important;
      line-height: 20px;
    }
    ::v-deep .no-suffix {
      .el-input__inner {
        cursor: text;
      }
    }
  }
}
</style>