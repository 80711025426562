<template>
  <span>
    <!-- 名称 主题-->
    <a
      class="cursor-pointer allow-click"
      :target="targetType"
      :href="nameHrefUrl"
      @click="jumpTo()"
    >
      <span class="cannotEdit">
        {{ row[`${column.schemefieldName}`] }}
      </span>
      <svg
        class="icon editIconImg"
        aria-hidden="true"
        style="display: none"
        @click="editCell(column, row)"
      >
        <use href="#icon-pen"></use>
      </svg>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    // 当前行数据
    row: {
      type: Object,
      default: () => ({}),
    },
    // 当前列数据
    column: {
      type: Object,
      default: () => ({}),
    },
    // 当前scope
    scopeObj: {
        type: Object,
        default: ()=>({})
    },
    // 打开详情页方式
    targetType: {
      type: String,
      default: "_self",
    },
  },
  data() {
    return {
      nameHrefUrl:'javascript:void(0);',
    };
  },
  methods: {
    /**
     * 通知父组件内联编辑
     */
    editCell(column, row) {
      this.$emit("editCell", column, row);
    },
    // 跳转详情页
    jumpTo() {
       // 在跳转详情页前存储下当前视图id，方便在返回对象列表页时仍然显示对应视图内容
        // this.$store.commit("setViewId", this.viewId);
        this.nameHrefUrl = this.row.id
          ? `#/commonObjects/detail/${this.row.id}/DETAIL`
          : 'javascript:void(0);';
    },
    
  },
};
</script>

<style lang="scss" scoped>
.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
// 编辑图标样式
.editIconImg {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  height: 12px;
}
.cannotEdit {
  box-sizing: border-box;
  padding-right: 15px;
}
.user_box {
      line-height: 23px;
      height: 23px;
      display: flex;
      .headPortrait {
        .logoname {
          font-size: 12px !important;
          line-height: 23px;
        }
      }
      a {
        width: calc(100% - 27px);
        margin-left: 5px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
</style>